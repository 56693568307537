import React, { Component } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import styles from './QuestionFilter.module.scss';
import SBTFilter from '../SBTs/SBTFilter.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faChevronDown, faStar, faRobot, faSpinner } from '@fortawesome/free-solid-svg-icons';

/**
 * The QuestionFilter component can run in two modes:
 * 1) Normal popup mode (resultsMode = false), used in SurveyTool’s “Questions” tab.
 * 2) “resultsMode” (resultsMode = true), used inline in SurveyResults for “Questions” mode results filtering.
 *
 * This version merges any standalone or zero-answer questions from questionsCache so they aren't dropped.
 * We also store that merged question array in our component state (rather than mutating this.props.questions),
 * which avoids the “Cannot assign to read-only property” error.
 */

const questionTypes = ['freeform', 'multichoice', 'rating', 'binary'];

const modalStyles = {
  backgroundColor: 'white',
  fontSize: '16px'
};

class QuestionFilter extends Component {
  constructor(props) {
    super(props);

    // If resultsMode & a parent-provided filterState exist, use it. Otherwise fallback to defaults.
    const usingResultsMode = this.props.resultsMode || false;
    const existingFilterState =
      usingResultsMode && this.props.filterState ? this.props.filterState : {};

    // Merge the user-supplied questions with any in localStorage so zero-answer questions appear.
    const mergedQuestions = this.mergeQuestionsWithCache(props.questions);

    this.state = {
      // Normal question type filtering
      selectedTypes: existingFilterState.selectedTypes || this.props.initialSelectedTypes || [],
      sortByImportance: existingFilterState.sortByImportance || false,

      // We'll store the merged question array here, so we never mutate this.props.questions.
      mergedQuestions,

      // SBT-based filtered question subset
      sbtFilteredQuestions: null,
      filterLoading: false,

      // Collapsible sections for the UI
      expandedSections: {
        types: true,
        sbts: false,
        popular: false,
        ai: false,
        tags: false
      },

      // AI search features
      aiSearchQuery: existingFilterState.aiSearchQuery || '',
      aiModeEnabled: existingFilterState.aiModeEnabled || false,

      // Temporary states for popup usage (if not in resultsMode)
      pendingSelectedTypes:
        existingFilterState.selectedTypes || this.props.initialSelectedTypes || [],
      pendingSortByImportance: existingFilterState.sortByImportance || false,
      pendingSbtFilteredQuestions: null,

      pendingShowTopQuestions: existingFilterState.showTopQuestions || false,
      pendingTopQuestionsCount: existingFilterState.topQuestionsCount || 10,
      showTopQuestions: existingFilterState.showTopQuestions || false,
      topQuestionsCount: existingFilterState.topQuestionsCount || 10,

      pendingShowTopQuestionsByResponses: existingFilterState.showTopQuestionsByResponses || false,
      showTopQuestionsByResponses: existingFilterState.showTopQuestionsByResponses || false,

      // For filtering by question tags
      selectedTags: existingFilterState.selectedTags || [],

      // Count of current filtered set
      filteredQuestionsCount: 0,

      // Where we store question responses if none come as props
      cachedQuestionResponses: {},

      // Local copy of SBT filter state
      sbtFilterLocalState: existingFilterState.sbtFilterLocalState || null,

      // A guard object to avoid repeated re-filter calls
      lastAppliedFilterState: null
    };
  }

  /**
   * Merges props.questions with any question IDs from localStorage’s questionsCache
   * so that newly created or zero-answer questions appear as well.
   */
  mergeQuestionsWithCache(sourceQuestions) {
    if (!sourceQuestions || !Array.isArray(sourceQuestions)) return sourceQuestions || [];
    if (!this.props.network || !this.props.network.id) return sourceQuestions;

    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem('questionsCache') || '';
    let questionsCache = {};
    try {
      questionsCache = JSON.parse(questionsCacheStr);
    } catch (err) {
      questionsCache = {};
    }
    if (!questionsCache[networkID] || !questionsCache[networkID].questions) {
      return sourceQuestions;
    }

    const allCacheQIDs = Object.keys(questionsCache[networkID].questions);
    const existingIDs = new Set(sourceQuestions.map((q) => (q.id || '').toLowerCase()));

    // Merge them
    const merged = [...sourceQuestions];
    allCacheQIDs.forEach((qIdLower) => {
      if (!existingIDs.has(qIdLower)) {
        const qObj = questionsCache[networkID].questions[qIdLower];
        if (qObj) {
          merged.push({ ...qObj });
        }
      }
    });

    return merged;
  }

  componentDidMount() {
    // If local storage is enabled, load any prior filter states
    if (this.props.enableLocalStorage) {
      this.loadFilterStateFromLocalStorage();
    }
    // If no questionResponses are given, try localStorage
    if (!this.props.questionResponses) {
      this.loadQuestionResponsesFromLocalStorage();
    } else {
      this.setState({ cachedQuestionResponses: this.props.questionResponses });
    }
    this.computeFilteredQuestionsCount();
  }

  componentDidUpdate(prevProps, prevState) {
    // If the parent re-renders with a new question array, re-merge
    if (prevProps.questions !== this.props.questions) {
      const reMerged = this.mergeQuestionsWithCache(this.props.questions);
      this.setState({ mergedQuestions: reMerged }, () => {
        this.computeFilteredQuestionsCount();
      });
    }

    // If these states changed, re-check how many questions remain
    if (
      prevState.pendingSelectedTypes !== this.state.pendingSelectedTypes ||
      prevState.pendingSortByImportance !== this.state.pendingSortByImportance ||
      prevState.pendingSbtFilteredQuestions !== this.state.pendingSbtFilteredQuestions ||
      prevState.aiSearchQuery !== this.state.aiSearchQuery ||
      prevState.pendingShowTopQuestions !== this.state.pendingShowTopQuestions ||
      prevState.pendingTopQuestionsCount !== this.state.pendingTopQuestionsCount ||
      prevState.pendingShowTopQuestionsByResponses !== this.state.pendingShowTopQuestionsByResponses ||
      prevState.selectedTags !== this.state.selectedTags ||
      prevState.aiModeEnabled !== this.state.aiModeEnabled
    ) {
      this.computeFilteredQuestionsCount();
    }

    // Re-save to local storage if enabled
    if (this.props.enableLocalStorage) {
      this.saveFilterStateToLocalStorage();
    }
  }

  /** Load filter data from localStorage if available. */
  loadFilterStateFromLocalStorage() {
    try {
      const modeKey =
        this.props.filterType === 'results'
          ? 'questionFilterState_results'
          : 'questionFilterState_questions';
      const saved = localStorage.getItem(modeKey);
      if (saved) {
        const parsed = JSON.parse(saved);

        this.setState((prevState) => ({
          selectedTypes: parsed.selectedTypes || prevState.selectedTypes,
          sortByImportance:
            typeof parsed.sortByImportance === 'boolean'
              ? parsed.sortByImportance
              : prevState.sortByImportance,
          showTopQuestions:
            typeof parsed.showTopQuestions === 'boolean'
              ? parsed.showTopQuestions
              : prevState.showTopQuestions,
          topQuestionsCount: parsed.topQuestionsCount || prevState.topQuestionsCount,
          aiSearchQuery: parsed.aiSearchQuery || prevState.aiSearchQuery,
          sbtFilterLocalState: parsed.sbtFilterLocalState || prevState.sbtFilterLocalState,
          selectedTags: parsed.selectedTags || prevState.selectedTags,
          showTopQuestionsByResponses:
            typeof parsed.showTopQuestionsByResponses === 'boolean'
              ? parsed.showTopQuestionsByResponses
              : prevState.showTopQuestionsByResponses,
          aiModeEnabled:
            typeof parsed.aiModeEnabled === 'boolean'
              ? parsed.aiModeEnabled
              : prevState.aiModeEnabled,

          // Also set pending states if in popup mode
          pendingSelectedTypes: parsed.selectedTypes || prevState.pendingSelectedTypes,
          pendingSortByImportance:
            typeof parsed.sortByImportance === 'boolean'
              ? parsed.sortByImportance
              : prevState.pendingSortByImportance,
          pendingShowTopQuestions:
            typeof parsed.showTopQuestions === 'boolean'
              ? parsed.showTopQuestions
              : prevState.pendingShowTopQuestions,
          pendingTopQuestionsCount:
            parsed.topQuestionsCount || prevState.pendingTopQuestionsCount,
          pendingShowTopQuestionsByResponses:
            typeof parsed.showTopQuestionsByResponses === 'boolean'
              ? parsed.showTopQuestionsByResponses
              : prevState.pendingShowTopQuestionsByResponses
        }));
      }
    } catch (error) {
      console.error('Error loading filter state from localStorage:', error);
    }
  }

  /** Save current filter data to localStorage if enabled. */
  saveFilterStateToLocalStorage() {
    try {
      const {
        selectedTypes,
        sortByImportance,
        showTopQuestions,
        topQuestionsCount,
        aiSearchQuery,
        sbtFilterLocalState,
        selectedTags,
        showTopQuestionsByResponses,
        aiModeEnabled
      } = this.state;

      const dataToStore = {
        selectedTypes,
        sortByImportance,
        showTopQuestions,
        topQuestionsCount,
        aiSearchQuery,
        sbtFilterLocalState,
        selectedTags,
        showTopQuestionsByResponses,
        aiModeEnabled
      };

      const modeKey =
        this.props.filterType === 'results'
          ? 'questionFilterState_results'
          : 'questionFilterState_questions';
      localStorage.setItem(modeKey, JSON.stringify(dataToStore));
    } catch (error) {
      console.error('Error saving filter state to localStorage:', error);
    }
  }

  /** Attempt to load question-level responses from local cache if not provided in props. */
  loadQuestionResponsesFromLocalStorage() {
    if (!this.props.network || !this.props.network.id) {
      console.warn('Network ID undefined; cannot load questionResponses from localStorage.');
      return;
    }
    try {
      const networkID = this.props.network.id.toString();
      let questionsCacheStr = localStorage.getItem('questionsCache');
      let questionsCache = {};
      if (questionsCacheStr) {
        try {
          questionsCache = JSON.parse(questionsCacheStr);
        } catch (e) {
          console.error('Error parsing questionsCache:', e);
          questionsCache = {};
        }
      }
      if (!questionsCache[networkID]) {
        return;
      }
      const cachedResponses = questionsCache[networkID].questionResponses || {};
      this.setState({ cachedQuestionResponses: cachedResponses });
    } catch (error) {
      console.error('Error in loadQuestionResponsesFromLocalStorage:', error);
    }
  }

  parseResponse = (responseData) => {
    try {
      return typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    } catch (error) {
      console.error('Error parsing response data in QuestionFilter:', error);
      return null;
    }
  };

  setFilterLoading = (loading) => {
    this.setState({ filterLoading: loading });
    if (this.props.setFilterLoading) {
      this.props.setFilterLoading(loading);
    }
  };

  /**
   * Callback invoked by SBTFilter. `filtered` can be an array or an object with:
   *    { filteredQuestions, filteredResponsesByQuestion }
   */
  handleFilteredQuestions = (filtered, newSbtFilterLocalState) => {
    let realFilteredQuestions = [];
    let filteredResponsesByQuestion = {};

    if (Array.isArray(filtered)) {
      realFilteredQuestions = filtered;
    } else if (
      filtered &&
      filtered.filteredQuestions &&
      Array.isArray(filtered.filteredQuestions)
    ) {
      realFilteredQuestions = filtered.filteredQuestions;
      filteredResponsesByQuestion = filtered.filteredResponsesByQuestion || {};
    }

    this.setState(
      {
        pendingSbtFilteredQuestions: realFilteredQuestions,
        sbtFilterLocalState: newSbtFilterLocalState
      },
      () => {
        this.handleApplyFilters(true);
      }
    );

    // If the parent wants the combined object
    if (this.props.onFilter) {
      const newFilterState = {
        ...this.getCurrentFilterState(),
        sbtFilterLocalState: newSbtFilterLocalState
      };
      if (Object.keys(filteredResponsesByQuestion).length > 0) {
        this.props.onFilter(
          {
            filteredQuestions: realFilteredQuestions,
            filteredResponsesByQuestion
          },
          newFilterState
        );
      } else {
        this.props.onFilter(realFilteredQuestions, newFilterState);
      }
    }
  };

  // Additional SBT callbacks if in "creatorAndResponder" mode
  handleFilterCreators = (allowedQuestions, filterState) => {
    // no-op by default
  };
  handleFilterResponders = (allowedResponses, filterState) => {
    // no-op by default
  };

  /** Gathers all current filter state settings (so parent can see them if needed). */
  getCurrentFilterState() {
    return {
      selectedTypes: this.state.selectedTypes,
      sortByImportance: this.state.sortByImportance,
      showTopQuestions: this.state.showTopQuestions,
      topQuestionsCount: this.state.topQuestionsCount,
      aiSearchQuery: this.state.aiSearchQuery,
      sbtFilterLocalState: this.state.sbtFilterLocalState,
      selectedTags: this.state.selectedTags,
      showTopQuestionsByResponses: this.state.showTopQuestionsByResponses,
      aiModeEnabled: this.state.aiModeEnabled
    };
  }

  /**
   * A stub for "AI-based text search." You could implement advanced logic.
   * For now, it returns the same array if no query or aiMode is disabled.
   */
  applyAISearchFilter(questions, aiSearchQuery) {
    if (!this.state.aiModeEnabled || !aiSearchQuery.trim()) {
      return questions;
    }
    // ... advanced logic could go here ...
    return questions;
  }

  /**
   * This is triggered whenever the user changes any filter or SBT selection.
   * It either updates the "pending" states (in popup mode) or applies them directly (in resultsMode),
   * then calls parent’s onFilter with the final question set.
   */
  handleApplyFilters(immediate = false) {
    const { mergedQuestions } = this.state;
    if (!mergedQuestions) return;

    const newSelectedTypes = this.state.pendingSelectedTypes;
    const newSortByImportance = this.state.pendingSortByImportance;
    const newShowTopQuestions = this.state.pendingShowTopQuestions;
    const newTopQuestionsCount = this.state.pendingTopQuestionsCount;
    const newAiSearchQuery = this.state.aiSearchQuery;
    const newPendingSbtFilteredQuestions = this.state.pendingSbtFilteredQuestions;
    const newShowTopQuestionsByResponses = this.state.pendingShowTopQuestionsByResponses;
    const newSelectedTags = this.state.selectedTags;
    const newAiModeEnabled = this.state.aiModeEnabled;

    const potentialFilterStateObj = {
      newSelectedTypes: [...newSelectedTypes].sort(),
      newSortByImportance,
      newShowTopQuestions,
      newTopQuestionsCount,
      newAiSearchQuery,
      newPendingSbtFilteredQuestionsLength: newPendingSbtFilteredQuestions
        ? newPendingSbtFilteredQuestions.length
        : -1,
      newShowTopQuestionsByResponses,
      newSelectedTags: [...newSelectedTags].sort(),
      newAiModeEnabled
    };

    // Avoid re-running if nothing changed:
    if (
      this.state.lastAppliedFilterState &&
      JSON.stringify(this.state.lastAppliedFilterState) === JSON.stringify(potentialFilterStateObj)
    ) {
      return;
    }
    this.setState({ lastAppliedFilterState: potentialFilterStateObj });

    // “Immediate” means resultsMode or the user wants an immediate filter
    if (!immediate) {
      this.setState({
        selectedTypes: newSelectedTypes,
        sortByImportance: newSortByImportance,
        showTopQuestions: newShowTopQuestions,
        topQuestionsCount: newTopQuestionsCount,
        aiSearchQuery: newAiSearchQuery,
        sbtFilteredQuestions: newPendingSbtFilteredQuestions,
        showTopQuestionsByResponses: newShowTopQuestionsByResponses,
        selectedTags: newSelectedTags,
        aiModeEnabled: newAiModeEnabled
      });
    } else {
      this.setState({
        selectedTypes: newSelectedTypes,
        sortByImportance: newSortByImportance,
        showTopQuestions: newShowTopQuestions,
        topQuestionsCount: newTopQuestionsCount,
        aiSearchQuery: newAiSearchQuery,
        sbtFilteredQuestions: newPendingSbtFilteredQuestions,
        showTopQuestionsByResponses: newShowTopQuestionsByResponses,
        selectedTags: newSelectedTags,
        aiModeEnabled: newAiModeEnabled
      });
    }

    // If “Show top X by total importance” or “by # responses”, skip normal filters
    if (newShowTopQuestions || newShowTopQuestionsByResponses) {
      let finalQuestions = [...mergedQuestions];
      if (newPendingSbtFilteredQuestions !== null) {
        const sbtIds = newPendingSbtFilteredQuestions.map((q) => q.id);
        finalQuestions = finalQuestions.filter((q) => sbtIds.includes(q.id));
      }
      if (newSelectedTypes.length > 0) {
        finalQuestions = finalQuestions.filter((q) => newSelectedTypes.includes(q.type));
      }
      if (newSelectedTags.length > 0) {
        finalQuestions = finalQuestions.filter((q) => {
          if (!q.tags || !Array.isArray(q.tags)) return false;
          return q.tags.some((tag) => newSelectedTags.includes(tag));
        });
      }
      finalQuestions = this.applyAISearchFilter(finalQuestions, newAiSearchQuery);

      // If user wants top X by # of responses:
      if (newShowTopQuestionsByResponses) {
        const { cachedQuestionResponses } = this.state;
        const questionsWithCount = finalQuestions.map((q) => {
          const qLower = (q.id || '').toLowerCase();
          const responsesObj = cachedQuestionResponses[qLower] || {};
          const count = Object.keys(responsesObj).length;
          return { ...q, totalResponses: count };
        });
        questionsWithCount.sort((a, b) => b.totalResponses - a.totalResponses);
        const topQuestionsByResp = questionsWithCount.slice(0, newTopQuestionsCount);

        const filterState = this.buildFilterState();
        if (this.props.onFilter) {
          this.props.onFilter(topQuestionsByResp, filterState);
        }
        this.computeFilteredQuestionsCount();
        return;
      }

      // Else user wants top X by total importance
      const relevantResponses = this.props.questionResponses || this.state.cachedQuestionResponses;
      const questionsWithImportance = finalQuestions.map((q) => {
        const qLower = (q.id || '').toLowerCase();
        const respondersObj = relevantResponses[qLower] || {};
        let totalImportance = 0;
        Object.keys(respondersObj).forEach((resp) => {
          const parsed = this.parseResponse(respondersObj[resp]);
          totalImportance += parsed?.importance || 0;
        });
        return { ...q, totalImportance };
      });
      questionsWithImportance.sort((a, b) => b.totalImportance - a.totalImportance);
      const topQuestions = questionsWithImportance.slice(0, newTopQuestionsCount);

      const filterState = this.buildFilterState();
      if (this.props.onFilter) {
        this.props.onFilter(topQuestions, filterState);
      }
      this.computeFilteredQuestionsCount();
      return;
    }

    // Normal scenario (no top-X selection)
    let finalQuestions = [...mergedQuestions];
    // If we have an SBT subset from SBTFilter
    if (newPendingSbtFilteredQuestions !== null) {
      const sbtIds = newPendingSbtFilteredQuestions.map((q) => q.id);
      finalQuestions = finalQuestions.filter((q) => sbtIds.includes(q.id));
    }
    // Filter by question type
    if (newSelectedTypes.length > 0) {
      finalQuestions = finalQuestions.filter((q) => newSelectedTypes.includes(q.type));
    }
    // Filter by tags
    if (newSelectedTags.length > 0) {
      finalQuestions = finalQuestions.filter((q) => {
        if (!q.tags || !Array.isArray(q.tags)) return false;
        return q.tags.some((tag) => newSelectedTags.includes(tag));
      });
    }
    // AI search
    if (newAiSearchQuery.trim() !== '' && newAiModeEnabled) {
      finalQuestions = this.applyAISearchFilter(finalQuestions, newAiSearchQuery);
    }
    // Sort by importance
    if (newSortByImportance) {
      const relevantResponses = this.props.questionResponses || this.state.cachedQuestionResponses;
      const questionsWithImportance = finalQuestions.map((q) => {
        const qLower = (q.id || '').toLowerCase();
        const respondersObj = relevantResponses[qLower] || {};
        let totalImportance = 0;
        Object.keys(respondersObj).forEach((resp) => {
          const parsed = this.parseResponse(respondersObj[resp]);
          totalImportance += parsed?.importance || 0;
        });
        return { ...q, totalImportance };
      });
      questionsWithImportance.sort((a, b) => b.totalImportance - a.totalImportance);
      finalQuestions = questionsWithImportance;
    }

    // Return final array
    const filterState = this.buildFilterState();
    if (this.props.onFilter) {
      this.props.onFilter(finalQuestions, filterState);
    }
    this.computeFilteredQuestionsCount();
  }

  /** Builds the final filter state object to pass up. */
  buildFilterState() {
    return {
      selectedTypes: this.state.selectedTypes,
      sortByImportance: this.state.sortByImportance,
      showTopQuestions: this.state.showTopQuestions,
      topQuestionsCount: this.state.topQuestionsCount,
      aiSearchQuery: this.state.aiSearchQuery,
      sbtFilterLocalState: this.state.sbtFilterLocalState,
      selectedTags: this.state.selectedTags,
      showTopQuestionsByResponses: this.state.showTopQuestionsByResponses,
      aiModeEnabled: this.state.aiModeEnabled
    };
  }

  /** Expand/collapse a filter section. */
  toggleSection = (section) => {
    this.setState((prevState) => ({
      expandedSections: {
        ...prevState.expandedSections,
        [section]: !prevState.expandedSections[section]
      }
    }));
  };

  /**
   * Determines how many questions remain given all “pending” filters,
   * so we can show “Filter Questions (X match)” in the UI.
   */
  computeFilteredQuestionsCount() {
    const { mergedQuestions } = this.state;
    if (!mergedQuestions) {
      if (this.state.filteredQuestionsCount !== 0) {
        this.setState({ filteredQuestionsCount: 0 });
      }
      return;
    }

    const {
      pendingSelectedTypes,
      pendingSbtFilteredQuestions,
      pendingShowTopQuestions,
      pendingTopQuestionsCount,
      pendingShowTopQuestionsByResponses,
      aiSearchQuery,
      selectedTags,
      aiModeEnabled
    } = this.state;

    if (pendingShowTopQuestions || pendingShowTopQuestionsByResponses) {
      const newCount = Math.min(mergedQuestions.length, pendingTopQuestionsCount);
      if (newCount !== this.state.filteredQuestionsCount) {
        this.setState({ filteredQuestionsCount: newCount });
      }
      return;
    }

    let finalList = [...mergedQuestions];
    if (pendingSbtFilteredQuestions !== null) {
      const sbtIds = pendingSbtFilteredQuestions.map((q) => q.id);
      finalList = finalList.filter((q) => sbtIds.includes(q.id));
    }
    if (pendingSelectedTypes.length > 0) {
      finalList = finalList.filter((q) => pendingSelectedTypes.includes(q.type));
    }
    if (selectedTags.length > 0) {
      finalList = finalList.filter((q) => {
        if (!q.tags || !Array.isArray(q.tags)) return false;
        return q.tags.some((tag) => selectedTags.includes(tag));
      });
    }
    if (aiSearchQuery.trim() !== '' && aiModeEnabled) {
      finalList = this.applyAISearchFilter(finalList, aiSearchQuery);
    }

    const newLength = finalList.length;
    if (newLength !== this.state.filteredQuestionsCount) {
      this.setState({ filteredQuestionsCount: newLength });
    }
  }

  handleTypeSelection = (type) => {
    let newSelectedTypes = [...this.state.pendingSelectedTypes];
    if (newSelectedTypes.includes(type)) {
      newSelectedTypes = newSelectedTypes.filter((t) => t !== type);
    } else {
      newSelectedTypes.push(type);
    }
    this.setState({ pendingSelectedTypes: newSelectedTypes }, () => {
      this.handleApplyFilters(true);
    });
  };

  handleTagSelection = (tag) => {
    let updatedTags = [...this.state.selectedTags];
    if (updatedTags.includes(tag)) {
      updatedTags = updatedTags.filter((t) => t !== tag);
    } else {
      updatedTags.push(tag);
    }
    this.setState({ selectedTags: updatedTags }, () => {
      this.handleApplyFilters(true);
    });
  };

  handleSortByImportance = () => {
    this.setState(
      (prevState) => ({ pendingSortByImportance: !prevState.pendingSortByImportance }),
      () => {
        this.handleApplyFilters(true);
      }
    );
  };

  /**
   * In popup mode, if user cancels, revert pending changes to actual filter states.
   * Then optionally close the modal (if not in resultsMode).
   */
  handleCancelFilters = () => {
    this.setState(
      {
        pendingSelectedTypes: this.state.selectedTypes,
        pendingSortByImportance: this.state.sortByImportance,
        pendingSbtFilteredQuestions: this.state.sbtFilteredQuestions,
        pendingShowTopQuestions: this.state.showTopQuestions,
        pendingTopQuestionsCount: this.state.topQuestionsCount,
        pendingShowTopQuestionsByResponses: this.state.showTopQuestionsByResponses,
        aiSearchQuery: this.state.aiSearchQuery,
        sbtFilterLocalState: null,
        selectedTags: this.state.selectedTags,
        aiModeEnabled: this.state.aiModeEnabled
      },
      () => {
        if (!this.props.resultsMode) {
          this.props.toggleFilterModal();
        }
      }
    );
  };

  /**
   * Toggle the “top X questions by total importance” or by # responses flags.
   */
  toggleShowTopQuestions = (byResponses = false) => {
    if (byResponses) {
      this.setState(
        (prev) => ({
          pendingShowTopQuestionsByResponses: !prev.pendingShowTopQuestionsByResponses,
          pendingShowTopQuestions: false
        }),
        () => {
          this.handleApplyFilters(true);
        }
      );
    } else {
      this.setState(
        (prev) => ({
          pendingShowTopQuestions: !prev.pendingShowTopQuestions,
          pendingShowTopQuestionsByResponses: false
        }),
        () => {
          this.handleApplyFilters(true);
        }
      );
    }
  };

  /**
   * Renders a collapsible section titled `title` with content. We track expand state in expandedSections.
   */
  renderCollapsibleSection(title, sectionKey, icon, content) {
    const { expandedSections } = this.state;
    const isOpen = expandedSections[sectionKey];
    return (
      <div className={styles.filterSection}>
        <div
          className={styles.sectionHeader}
          onClick={() => this.toggleSection(sectionKey)}
          style={{ cursor: 'pointer' }}
        >
          <h3>
            <FontAwesomeIcon icon={icon} className="me-2" />
            {title}
          </h3>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`${styles.icon} ${isOpen ? styles.expanded : ''}`}
          />
        </div>
        {isOpen && <div className={styles.sectionContent}>{content}</div>}
      </div>
    );
  }

  /**
   * Gathers all unique tags from this.state.mergedQuestions so user can filter by tags.
   */
  getAllTagsFromQuestions() {
    const { mergedQuestions } = this.state;
    if (!mergedQuestions || !Array.isArray(mergedQuestions)) return [];
    const tagSet = new Set();
    mergedQuestions.forEach((q) => {
      if (q.tags && Array.isArray(q.tags)) {
        q.tags.forEach((tag) => tagSet.add(tag));
      }
    });
    return Array.from(tagSet);
  }

  render() {
    const {
      pendingSelectedTypes,
      aiSearchQuery,
      filteredQuestionsCount,
      pendingShowTopQuestions,
      pendingTopQuestionsCount,
      filterLoading,
      sbtFilterLocalState,
      pendingShowTopQuestionsByResponses,
      selectedTags,
      aiModeEnabled
    } = this.state;

    // If the user checks “Show top X,” we disable other filters in the UI
    const isOtherFiltersDisabled = pendingShowTopQuestions || pendingShowTopQuestionsByResponses;
    // If resultsMode, we display inline rather than a modal
    const isInline = this.props.resultsMode;
    // If we use “creatorAndResponder” in SBT mode
    const usingCreatorAndResponder = !!this.props.creatorAndResponderMode;

    // For a small “Filter” button if we’re in resultsMode
    let questionFilterToggleButton = null;
    if (usingCreatorAndResponder && isInline) {
      questionFilterToggleButton = (
        <Button onClick={this.props.toggleFilterModal} style={{ marginBottom: '10px' }}>
          Filter
        </Button>
      );
    }

    // Collect all distinct tags to show in "Tags" section
    const allTags = this.getAllTagsFromQuestions();

    // Build the collapsible UI content
    const bodyContent = (
      <div>
        {this.renderCollapsibleSection(
          'Most Popular',
          'popular',
          faStar,
          <div>
            <FormGroup>
              <Label className={styles.filterOption}>
                <Input
                  type="checkbox"
                  checked={pendingShowTopQuestions}
                  onChange={() => {
                    this.toggleShowTopQuestions(false);
                  }}
                  disabled={false}
                />
                Show top
                <Input
                  type="number"
                  min="1"
                  value={pendingTopQuestionsCount}
                  onChange={(e) => {
                    const val = e.target.value ? parseInt(e.target.value, 10) : 1;
                    this.setState({ pendingTopQuestionsCount: val }, () => {
                      if (pendingShowTopQuestions || pendingShowTopQuestionsByResponses) {
                        this.handleApplyFilters(true);
                      }
                    });
                  }}
                  disabled={!pendingShowTopQuestions && !pendingShowTopQuestionsByResponses}
                  id={styles.topQuestionsCountInput}
                />
                questions (by total importance)
              </Label>
            </FormGroup>

            <FormGroup>
              <Label className={styles.filterOption}>
                <Input
                  type="checkbox"
                  checked={pendingShowTopQuestionsByResponses}
                  onChange={() => {
                    this.toggleShowTopQuestions(true);
                  }}
                  disabled={false}
                />
                Show top
                <Input
                  type="number"
                  min="1"
                  value={pendingTopQuestionsCount}
                  onChange={(e) => {
                    const val = e.target.value ? parseInt(e.target.value, 10) : 1;
                    this.setState({ pendingTopQuestionsCount: val }, () => {
                      if (pendingShowTopQuestions || pendingShowTopQuestionsByResponses) {
                        this.handleApplyFilters(true);
                      }
                    });
                  }}
                  disabled={!pendingShowTopQuestions && !pendingShowTopQuestionsByResponses}
                  id={styles.topQuestionsCountInput}
                />
                questions (by # of responses)
              </Label>
            </FormGroup>
            {(pendingShowTopQuestions || pendingShowTopQuestionsByResponses) && (
              <small className="text-muted">
                This overrides other filters (type, tag, AI, etc.)
              </small>
            )}
          </div>
        )}

        <div className={isOtherFiltersDisabled ? styles.disabledSection : ''}>
          {this.renderCollapsibleSection(
            'Question Types',
            'types',
            faFilter,
            <FormGroup>
              {questionTypes.map((type, idx) => (
                <Label key={idx} className={styles.filterOption}>
                  <Input
                    type="checkbox"
                    value={type}
                    checked={pendingSelectedTypes.includes(type)}
                    onChange={() => this.handleTypeSelection(type)}
                    disabled={isOtherFiltersDisabled}
                  />
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </Label>
              ))}
            </FormGroup>
          )}

          {this.renderCollapsibleSection(
            usingCreatorAndResponder
              ? 'SBT Group(s) of Question Creator or Responder'
              : 'SBT Group(s) of Question Creator',
            'sbts',
            faStar,
            isOtherFiltersDisabled ? (
              <p className={styles.disabledText}>
                Disabled by “Top X questions” selection.
              </p>
            ) : (
              <SBTFilter
                items={this.state.mergedQuestions}
                provider={this.props.provider}
                network={this.props.network}
                mode={usingCreatorAndResponder ? 'creatorAndResponder' : 'creator'}
                onFilter={this.handleFilteredQuestions}
                onFilterCreators={(creatorSubset, fs) => this.handleFilterCreators(creatorSubset, fs)}
                onFilterResponders={(respSubset, fs) =>
                  this.handleFilterResponders(respSubset, fs)
                }
                setFilterLoading={this.setFilterLoading}
                autoExpand={true}
                externalSBTFilterState={sbtFilterLocalState}
              />
            )
          )}

          {this.renderCollapsibleSection(
            'Tags',
            'tags',
            faFilter,
            isOtherFiltersDisabled ? (
              <p className={styles.disabledText}>
                Disabled by “Top X questions” selection.
              </p>
            ) : (
              <FormGroup>
                {allTags.length === 0 ? (
                  <p>No tags found in current questions.</p>
                ) : (
                  allTags.map((tag) => (
                    <Label key={tag} className={styles.filterOption}>
                      <Input
                        type="checkbox"
                        checked={selectedTags.includes(tag)}
                        onChange={() => this.handleTagSelection(tag)}
                      />
                      {tag}
                    </Label>
                  ))
                )}
              </FormGroup>
            )
          )}

          {this.renderCollapsibleSection(
            'AI Filter',
            'ai',
            faRobot,
            isOtherFiltersDisabled ? (
              <p className={styles.disabledText}>
                Disabled by “Top X questions” selection.
              </p>
            ) : (
              <FormGroup>
                <Label className={styles.filterOption} style={{ marginBottom: '10px' }}>
                  <Input
                    type="checkbox"
                    checked={aiModeEnabled}
                    onChange={() => {
                      this.setState({ aiModeEnabled: !aiModeEnabled }, () => {
                        this.handleApplyFilters(true);
                      });
                    }}
                  />
                  Enable AI-based Search
                </Label>
                <Input
                  type="text"
                  className={styles.searchInput}
                  placeholder="Enter AI filter text..."
                  value={aiSearchQuery}
                  disabled={!aiModeEnabled}
                  onChange={(e) => {
                    if (isInline) {
                      this.setState({ aiSearchQuery: e.target.value }, () => {
                        if (aiModeEnabled) {
                          this.handleApplyFilters(true);
                        }
                      });
                    } else {
                      this.setState({ aiSearchQuery: e.target.value });
                    }
                  }}
                />
                <small className="text-muted">
                  (Future placeholder for advanced LLM-based question search)
                </small>
              </FormGroup>
            )
          )}
        </div>

        {filterLoading && (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <p>Applying filter...</p>
          </div>
        )}
      </div>
    );

    // For resultsMode, we’ll hide the SBTFilter UI in an invisible block to let it run
    const hiddenSBTFilter = isInline ? (
      <div style={{ display: 'none' }}>
        <SBTFilter
          items={this.state.mergedQuestions}
          provider={this.props.provider}
          network={this.props.network}
          onFilter={this.handleFilteredQuestions}
          setFilterLoading={this.setFilterLoading}
          autoExpand={true}
          externalSBTFilterState={this.state.sbtFilterLocalState}
          mode={usingCreatorAndResponder ? 'creatorAndResponder' : 'creator'}
          hideUI={true}
        />
      </div>
    ) : null;

    // If we’re in resultsMode, everything is inline. Otherwise, we do a modal.
    if (isInline) {
      if (!this.props.showQuestionFilter && usingCreatorAndResponder) {
        return (
          <div className={styles.questionFilterInline}>
            {hiddenSBTFilter}
            {questionFilterToggleButton}
          </div>
        );
      }
      return (
        <div className={styles.questionFilterInline}>
          {hiddenSBTFilter}
          {questionFilterToggleButton}
          {bodyContent}
        </div>
      );
    } else {
      // Normal popup mode
      return (
        <Modal
          isOpen={this.props.filterModalOpen}
          toggle={this.handleCancelFilters}
          style={modalStyles}
        >
          <ModalHeader toggle={this.handleCancelFilters} className={styles.modalHeader}>
            Filter Questions ({filteredQuestionsCount})
          </ModalHeader>
          <ModalBody className={styles.modalBody}>{bodyContent}</ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={this.props.toggleFilterModal}>
              See Questions
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
}

export default QuestionFilter;
