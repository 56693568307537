import React, { Component } from 'react';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Label,
  Input,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Alert,
  CustomInput,
  Table,
  Progress
} from 'reactstrap';
import Select from 'react-select';

import styles from './SurveyResults.module.scss';
import "../../assets/css/m_w.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faCaretUp,
  faCaretDown,
  faCheck,
  faTimes,
  faArrowLeft,
  faArrowRight,
  faQuestionCircle,
  faSpinner,
  faSearch,
  faExpand,
  faExternalLinkAlt,
  faFilter,
  faExclamationCircle,
  faClipboard,
  faCog,
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons';

import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';
import SBTFilter from '../SBTs/SBTFilter.jsx';
import QuestionFilter from './QuestionFilter';
import PolisReport from '../PolisReport/PolisReport';
import SingleQuestionResponse from './SingleQuestionResponse';

/**
 * Helper that merges aggregator keys in lowercase, ensuring zero-response question IDs are included.
 */
function unifyAggregatorWithAllQuestionIDs(baseAggregator, allKnownQuestionIds = []) {
  const loweredMap = {};
  for (const key of Object.keys(baseAggregator)) {
    const lowerKey = key.toLowerCase();
    if (!loweredMap[lowerKey]) {
      loweredMap[lowerKey] = baseAggregator[key];
    } else {
      loweredMap[lowerKey] = loweredMap[lowerKey].concat(baseAggregator[key]);
    }
  }
  for (const qId of allKnownQuestionIds) {
    const qLower = qId.toLowerCase();
    if (!loweredMap[qLower]) {
      loweredMap[qLower] = [];
    }
  }
  return loweredMap;
}

class SurveyResults extends Component {
  constructor(props) {
    super(props);

    const localBookmarks = JSON.parse(localStorage.getItem('bookmarks')) || {
      questionIDs: [],
      surveyIDs: []
    };

    this.state = {
      responses: [],
      sbtFilteredResponses: [],
      csvData: '',
      exportType: 'Polis Report',
      alertMessage: '',
      loading: false,
      surveyTitle: '',
      surveyId: '',
      activeQuestionToggles: {},
      questionResponses: {},
      sbtFilteredQuestionResponses: {},
      aggregatorQuestionResponses: {},
      sbtFilteredAggregatorQuestionResponses: {},
      viewMode: this.props.viewMode, // 'survey' or 'questions'
      filterLoading: false,
      showQuestionFilter: false,
      filterState: this.props.filterState || {},
      bookmarkedQuestionIDs: localBookmarks.questionIDs || [],
      bookmarkedSurveyIDs: localBookmarks.surveyIDs || [],
      questionIdSortBy: 'responses',
      questionIdSortAsc: true,
      totalQuestionsCount: 0,
      filteredQuestionsCount: 0,
      totalResponsesCount: 0,
      filteredResponsesCount: 0,
      surveyViewMode: 'individuals', // aggregator vs. individuals
      aggregateQuestionResponses: {},
      sbtFilteredAggregateQuestionResponses: {},
      polisReportSelected: false,

      // chunk-based progress placeholders (not fully used)
      questionPartialLoading: false,
      questionPartialProgress: 0,
      questionPartialTotal: 0,

      responsePartialLoading: false,
      responsePartialProgress: 0,
      responsePartialTotal: 0,
      networkLatestBlock: 0,

      // We track local blocks for question & survey data
      questionLocalBlock: 0,
      responseLocalBlock: 0,
      surveyLocalBlock: 0,

      // track how many questions & responses are cached
      cachedQuestionsCount: 0,
      cachedSurveyResponsesCount: 0,

      // block targets for manual refresh
      refreshTargetQuestionBlock: 0,
      refreshTargetResponseBlock: 0,
      refreshTargetSurveyBlock: 0,

      localStoragePollingInterval: null,

      activeToggles: {}
    };

    this.questionIdTableRef = React.createRef();
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('popstate', this.handleUrlChange);
    this.handleUrlBasedView();

    // -------------------------------
    //  LEAVE THE IF AS IT IS, BUT ADD
    //  A TINY URL-UPDATE AFTER IT:
    // -------------------------------
    if (this.props.isOpen && localStorage.getItem("cacheHasLoaded") === "true") {
      this.startLocalStoragePolling();
      this.handleManualRefresh();
      this.fetchResponses();

      // After we've done the existing logic, append "/results" to URL if not present:
      if (!window.location.pathname.endsWith('/results')) {
        if (this.state.viewMode === 'questions') {
          window.history.pushState({}, '', '/questions/results');
        } else {
          if (this.props.surveyId) {
            window.history.pushState({}, '', `/survey/${this.props.surveyId}/results`);
          } else {
            window.history.pushState({}, '', '/questions/results');
          }
        }
      }
    }

    else if (this.props.isOpen) {
      // The modal is open at mount -> start polling & fetch
      this.startLocalStoragePolling();
      this.fetchResponses();

      // Similarly append "/results" if not present:
      if (!window.location.pathname.endsWith('/results')) {
        if (this.state.viewMode === 'questions') {
          window.history.pushState({}, '', '/questions/results');
        } else {
          if (this.props.surveyId) {
            window.history.pushState({}, '', `/survey/${this.props.surveyId}/results`);
          } else {
            window.history.pushState({}, '', '/questions/results');
          }
        }
      }
    }
  }

  componentWillUnmount() {
    // (Existing lines)
    this._isMounted = false;
    window.removeEventListener('popstate', this.handleUrlChange);
    this.stopLocalStoragePolling();
  
    // (NEW) If unmounting while still open, remove "/results" from the URL
    if (this.props.isOpen) {
      const currentPath = window.location.pathname;
      if (currentPath.includes('/results')) {
        let newPath = currentPath.replace('/results', '').replace(/\/+$/, '');
        // If that leaves nothing, fall back to whichever path logic you want:
        if (!newPath) {
          newPath =
            this.state.viewMode === 'questions'
              ? '/questions'
              : this.props.surveyId
                ? `/survey/${this.props.surveyId}`
                : '/questions';
        }
        window.history.pushState({}, '', newPath);
      }
    }
  }
  

  componentDidUpdate(prevProps, prevState) {
    // If the modal just closed, revert the URL
    if (prevProps.isOpen && !this.props.isOpen) {
      if (this.state.viewMode === 'questions') {
        window.history.pushState({}, '', '/questions');
      } else {
        if (this.props.surveyId) {
          window.history.pushState({}, '', `/survey/${this.props.surveyId}`);
        } else {
          window.history.pushState({}, '', '/questions');
        }
      }
    }

    // Watch for isOpen transitions (original code):
    if (!prevProps.isOpen && this.props.isOpen) {
      this.startLocalStoragePolling();
      this.fetchResponses();
    } else if (prevProps.isOpen && !this.props.isOpen) {
      this.stopLocalStoragePolling();
    }

    // If user toggles from "survey" <-> "questions"
    if (prevState.viewMode !== this.state.viewMode) {
      this.setState(
        {
          questionLocalBlock: 0,
          responseLocalBlock: 0,
          surveyLocalBlock: 0,
          refreshTargetQuestionBlock: 0,
          refreshTargetResponseBlock: 0,
          refreshTargetSurveyBlock: 0
        },
        () => {
          this.fetchResponses();
        }
      );
    }

    // If in "survey" mode and the surveyId changed
    if (
      this.state.viewMode === 'survey' &&
      this.props.surveyId !== prevProps.surveyId
    ) {
      this.setState(
        {
          surveyLocalBlock: 0,
          refreshTargetSurveyBlock: 0
        },
        () => {
          this.fetchResponses();
        }
      );
    }
  }

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleUrlChange = () => {
    this.handleUrlBasedView();
  };

  handleUrlBasedView = () => {
    const path = window.location.pathname;
    let newViewMode = 'survey';
    if (path === '/questions' || path.includes('/questions')) {
      newViewMode = 'questions';
    }
    if (this.state.viewMode !== newViewMode) {
      this.setState({ viewMode: newViewMode }, this.fetchResponses);
    }
  };

  // -----------------------------------------
  // LOCAL STORAGE POLLING
  // -----------------------------------------
  startLocalStoragePolling() {
    if (this.state.localStoragePollingInterval) return;
    const intervalId = setInterval(() => {
      if (!this._isMounted) return;
      this.pollLocalStorageForUpdates();
    }, 2000);
    this.setState({ localStoragePollingInterval: intervalId });
  }

  stopLocalStoragePolling() {
    if (this.state.localStoragePollingInterval) {
      clearInterval(this.state.localStoragePollingInterval);
      this.setState({ localStoragePollingInterval: null });
    }
  }

  pollLocalStorageForUpdates() {
    if (!this.props.network || !this.props.network.id) return;
    const netId = this.props.network.id.toString();

    // Load questionsCache
    let questionsCacheStr = localStorage.getItem('questionsCache') || '';
    let questionsCache = {};
    try {
      questionsCache = JSON.parse(questionsCacheStr);
    } catch {
      questionsCache = {};
    }
    if (!questionsCache[netId]) {
      questionsCache[netId] = {
        questionsLatestBlock: 0,
        questions: {},
        questionResponses: {},
        questionResponsesLatestBlock: 0
      };
    }

    // Load surveysCache
    let surveysCacheStr = localStorage.getItem('surveysCache') || '';
    let surveysCache = {};
    try {
      surveysCache = JSON.parse(surveysCacheStr);
    } catch {
      surveysCache = {};
    }
    if (!surveysCache[netId]) {
      surveysCache[netId] = {
        surveys: {},
        surveyResponses: {},
        surveyResponsesLatestBlock: {},
        surveysLatestBlock: 0
      };
    }

    // Attempt to get the chain’s latest block if not known
    let netLatest = this.state.networkLatestBlock;
    if (!netLatest || netLatest === 0) {
      contractScripts
        .getLatestBlockNumber(this.props.provider)
        .then((blk) => {
          if (this._isMounted) {
            this.setState({ networkLatestBlock: blk });
          }
        })
        .catch(() => {});
      netLatest = 0;
    }

    // Pull question info
    const newQuestionsCount = Object.keys(questionsCache[netId].questions || {}).length;
    const localQBlock = questionsCache[netId].questionsLatestBlock || 0;
    const localRespBlock = questionsCache[netId].questionResponsesLatestBlock || 0;

    // If we are in survey mode & have a specific survey
    let localSBlock = 0;
    let localSurveyResponsesCount = 0;
    if (this.state.viewMode === 'survey' && this.props.surveyId) {
      const sid = this.props.surveyId.toLowerCase();
      if (
        surveysCache[netId].surveyResponsesLatestBlock[sid] &&
        surveysCache[netId].surveyResponsesLatestBlock[sid] > 0
      ) {
        localSBlock = surveysCache[netId].surveyResponsesLatestBlock[sid];
      }
      if (surveysCache[netId].surveyResponses[sid]) {
        localSurveyResponsesCount = Object.keys(
          surveysCache[netId].surveyResponses[sid]
        ).length;
      }
    } else if (this.state.viewMode === 'survey') {
      localSBlock = 0;
      localSurveyResponsesCount = 0;
    }

    const blockOrRespChanged =
      localQBlock !== this.state.questionLocalBlock ||
      localRespBlock !== this.state.responseLocalBlock ||
      localSBlock !== this.state.surveyLocalBlock;

    const questionCountChanged = newQuestionsCount !== this.state.cachedQuestionsCount;
    const surveyResponseCountChanged =
      localSurveyResponsesCount !== this.state.cachedSurveyResponsesCount;

    if (blockOrRespChanged || questionCountChanged || surveyResponseCountChanged) {
      this.setState(
        {
          questionLocalBlock: localQBlock,
          responseLocalBlock: localRespBlock,
          surveyLocalBlock: localSBlock,
          cachedQuestionsCount: newQuestionsCount,
          cachedSurveyResponsesCount: localSurveyResponsesCount,
          networkLatestBlock: netLatest
        },
        () => {
          this.fetchResponses();
        }
      );
    }
  }

  parseResponse = (responseData) => {
    try {
      return typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    } catch (error) {
      console.error('Error parsing response data:', error);
      return null;
    }
  };

  fetchResponses = async () => {
    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID is undefined in fetchResponses. Cannot proceed.');
      return;
    }

    let fetchedChainBlock = this.state.networkLatestBlock || 0;
    if (!fetchedChainBlock) {
      try {
        fetchedChainBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
      } catch {
        fetchedChainBlock = 0;
      }
      if (this._isMounted) {
        this.setState({ networkLatestBlock: fetchedChainBlock });
      }
    }

    if (this.state.viewMode === 'survey') {
      this.fetchSurveyModeResponses();
    } else {
      this.fetchQuestionModeResponses();
    }
  };

  async fetchSurveyModeResponses() {
    const surveyID = this.props.surveyId ? this.props.surveyId.toLowerCase() : null;
    const netId = this.props.network?.id?.toString() || '';

    let surveysCacheStr = localStorage.getItem('surveysCache');
    let surveysCache = {};
    if (surveysCacheStr) {
      try {
        surveysCache = JSON.parse(surveysCacheStr);
      } catch (e) {
        surveysCache = {};
      }
    }
    if (!surveysCache[netId]) {
      return;
    }

    if (!surveyID) {
      this.setState({
        responses: [],
        sbtFilteredResponses: [],
        aggregateQuestionResponses: {},
        sbtFilteredAggregateQuestionResponses: {},
        surveyTitle: '',
        surveyId: '',
        totalQuestionsCount: 0,
        filteredQuestionsCount: 0,
        totalResponsesCount: 0,
        filteredResponsesCount: 0
      });
      return;
    }

    const aggregatorMap = {};
    const srMap = surveysCache[netId].surveyResponses[surveyID] || {};
    const allResponders = Object.keys(srMap);
    const rawResponses = allResponders.map((responder) => ({
      responder,
      surveyId: surveyID,
      response: srMap[responder]
    }));

    allResponders.forEach((responder) => {
      const rawResp = this.parseResponse(srMap[responder]);
      if (rawResp && rawResp.responses) {
        rawResp.responses.forEach((ans) => {
          const qIdL = (ans.questionID || '').toLowerCase();
          if (!aggregatorMap[qIdL]) aggregatorMap[qIdL] = [];
          aggregatorMap[qIdL].push({
            responder,
            questionId: qIdL,
            response: JSON.stringify(ans),
            timestamp: ans.timeStamp || rawResp.timeStamp || 0
          });
        });
      }
    });
    const totalRespondersCount = allResponders.length;

    let questionIDsInSurvey = [];
    if (surveysCache[netId].surveys[surveyID]) {
      questionIDsInSurvey =
        surveysCache[netId].surveys[surveyID].questionIDs || [];
    }

    let questionsCacheStr = localStorage.getItem('questionsCache');
    if (questionsCacheStr) {
      try {
        const qC = JSON.parse(questionsCacheStr);
        if (qC[netId] && qC[netId].questions) {
          for (const qId in qC[netId].questions) {
            const qObj = qC[netId].questions[qId];
            if (qObj.associatedSurveyId?.toLowerCase() === surveyID) {
              questionIDsInSurvey.push(qId.toLowerCase());
            }
          }
        }
      } catch (err) {
        console.error('Error merging questionIDs from questionsCache (survey):', err);
      }
    }

    questionIDsInSurvey.forEach((q) => {
      const lower = q.toLowerCase();
      if (!aggregatorMap[lower]) aggregatorMap[lower] = [];
    });

    const totalQCount = Object.keys(aggregatorMap).length;
    let foundTitle = '';
    if (surveysCache[netId].surveys[surveyID]) {
      foundTitle = surveysCache[netId].surveys[surveyID].title || '';
    }

    this.setState({
      aggregateQuestionResponses: aggregatorMap,
      sbtFilteredAggregateQuestionResponses: aggregatorMap,
      surveyTitle: foundTitle,
      surveyId: surveyID,
      totalQuestionsCount: totalQCount,
      filteredQuestionsCount: totalQCount,
      totalResponsesCount: totalRespondersCount,
      filteredResponsesCount: totalRespondersCount,
      responses: rawResponses,
      sbtFilteredResponses: rawResponses
    }, () => {
      this.generateCSVForSurveyMode();
    });
  }

  async fetchQuestionModeResponses() {
    const netId = this.props.network?.id?.toString();
    if (!netId) return;

    let questionsCacheStr = localStorage.getItem("questionsCache") || "";
    let questionsCache = {};
    try {
      questionsCache = JSON.parse(questionsCacheStr);
    } catch {
      questionsCache = {};
    }
    if (!questionsCache[netId]) {
      questionsCache[netId] = {
        questionsLatestBlock: 0,
        questions: {},
        questionResponses: {},
        questionResponsesLatestBlock: 0
      };
    }

    const partialQR = questionsCache[netId].questionResponses || {};
    const aggregatorMap = {};
    let totalResponseCount = 0;
    Object.keys(partialQR).forEach((qId) => {
      const lowerQ = qId.toLowerCase();
      aggregatorMap[lowerQ] = aggregatorMap[lowerQ] || [];
      const respondersMap = partialQR[qId] || {};
      Object.keys(respondersMap).forEach((rAddr) => {
        const rData = respondersMap[rAddr];
        const parsed = this.parseResponse(rData);
        if (parsed) {
          aggregatorMap[lowerQ].push({
            responder: rAddr.toLowerCase(),
            questionId: lowerQ,
            response: JSON.stringify(parsed),
            timestamp: parsed.timeStamp || 0
          });
          totalResponseCount++;
        }
      });
    });

    const knownQIDs = Object.keys(questionsCache[netId].questions || {});
    knownQIDs.forEach((q) => {
      const lowerQ = q.toLowerCase();
      if (!aggregatorMap[lowerQ]) aggregatorMap[lowerQ] = [];
    });

    const totalQ = Object.keys(aggregatorMap).length;
    this.setState({
      aggregatorQuestionResponses: aggregatorMap,
      sbtFilteredAggregatorQuestionResponses: aggregatorMap,
      questionResponses: partialQR,
      sbtFilteredQuestionResponses: partialQR,
      totalQuestionsCount: totalQ,
      filteredQuestionsCount: totalQ,
      totalResponsesCount: totalResponseCount,
      filteredResponsesCount: totalResponseCount
    }, () => {
      this.generateCSVForQuestionMode();
    });
  }

  generateCSVForSurveyMode = () => {
    const { responses } = this.state;
    if (!responses.length) {
      if (this._isMounted) {
        this.setState({ csvData: '' });
      }
      return;
    }

    const header =
      'responderAddress,questionID,questionPrompt,type,importance,answer,answerHash,additionalComments,answerEncrypted,additionalEncrypted,additionalHash\n';
    const csvRows = [];

    responses.forEach((response) => {
      const parsedResponse = this.parseResponse(response.response);
      if (parsedResponse && parsedResponse.responses) {
        parsedResponse.responses.forEach((answer) => {
          const row = [
            response.responder,
            answer.questionID || '',
            answer.prompt || '',
            answer.type || '',
            answer.importance || '',
            answer.answer ? answer.answer.value : '',
            answer.answer ? answer.answer.hash : '',
            answer.additional ? answer.additional.value : '',
            answer.answer ? answer.answer.encrypted : '',
            answer.additional ? answer.additional.encrypted : '',
            answer.additional ? answer.additional.hash : ''
          ].map((value) => `"${value !== undefined ? value : ''}"`).join(',');
          csvRows.push(row);
        });
      }
    });

    if (this._isMounted) {
      this.setState({ csvData: header + csvRows.join('\n') });
    }
  };

  generateCSVForQuestionMode = () => {
    const { aggregatorQuestionResponses } = this.state;
    const allQIDs = Object.keys(aggregatorQuestionResponses);
    if (!allQIDs.length) {
      if (this._isMounted) {
        this.setState({ csvData: '' });
      }
      return;
    }

    const header =
      'questionID,questionPrompt,type,responderAddress,importance,answer,answerHash,additionalComments,answerEncrypted,additionalEncrypted,additionalHash\n';
    const csvRows = [];

    allQIDs.forEach((qId) => {
      const arr = aggregatorQuestionResponses[qId] || [];
      arr.forEach((respObj) => {
        const parsed = this.parseResponse(respObj.response);
        if (parsed) {
          const questionId = parsed.questionID || '';
          const questionPrompt = parsed.prompt || '';
          const questionType = parsed.type || '';
          const answerVal = parsed.answer ? parsed.answer.value : '';
          const answerHash = parsed.answer ? parsed.answer.hash : '';
          const answerEnc = parsed.answer ? parsed.answer.encrypted : '';
          const additionalVal = parsed.additional ? parsed.additional.value : '';
          const additionalEnc = parsed.additional ? parsed.additional.encrypted : '';
          const additionalHash = parsed.additional ? parsed.additional.hash : '';
          const importance = parsed.importance || '';

          const row = [
            questionId,
            questionPrompt,
            questionType,
            respObj.responder,
            importance,
            answerVal,
            answerHash,
            additionalVal,
            answerEnc,
            additionalEnc,
            additionalHash
          ].map((value) => `"${value !== undefined ? value : ''}"`).join(',');
          csvRows.push(row);
        }
      });
    });

    if (this._isMounted) {
      this.setState({ csvData: header + csvRows.join('\n') });
    }
  };

  downloadCSV = () => {
    const { exportType, csvData, viewMode } = this.state;
    const timestamp = new Date().toISOString().replace(/[:.-]/g, '_');

    if (exportType === 'Polis Report') {
      this.setState({ alertMessage: 'Generated Polis Report below.' }, () => {
        this.setState({ polisReportSelected: true }, () => {
          setTimeout(() => {
            this.scrollToPolisReport();
          }, 300);
        });
      });
      return;
    }

    if (exportType === 'TalkToTheCity Report') {
      const lines = csvData.split('\n');
      if (!lines.length || !csvData.trim()) {
        this.setState({ alertMessage: 'No data available for TalkToTheCity Report.' });
        return;
      }
      const headerLine = lines[0];
      const columns = headerLine.split(',');
      const indicesToRemove = [];
      columns.forEach((col, idx) => {
        const clean = col.replace(/"/g, '').toLowerCase();
        if (
          clean.includes('answerhash') ||
          clean.includes('additionalencrypted') ||
          clean.includes('additionalhash')
        ) {
          indicesToRemove.push(idx);
        }
      });
      const revisedLines = lines.map((ln) => {
        const parts = ln.split(',');
        const filtered = parts.filter((_, idx) => !indicesToRemove.includes(idx));
        return filtered.join(',');
      });
      const finalCSV = revisedLines.join('\n');

      this.setState({
        alertMessage:
          'You can paste your CSV into https://talktothecity.org/new-report after signing in, to generate a talk-to-the-city style report.'
      });

      const blob = new Blob([finalCSV], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', `talktothecity_${timestamp}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      navigator.clipboard
        .writeText(finalCSV)
        .then(() => console.log('Copied TalkToTheCity CSV to clipboard'))
        .catch((err) => console.error('Failed to copy to clipboard', err));

      return;
    }

    if (!csvData.trim().length) {
      this.setState({ alertMessage: 'No data to download.' });
      return;
    }

    const blob = new Blob([csvData], { type: 'text/csv' });
    const modeLabel = viewMode === 'survey' ? 'surveyMode' : 'questionsMode';
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `survey_responses_${modeLabel}_${timestamp}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  handleExportTypeChange = (type) => {
    this.setState({ exportType: type, polisReportSelected: false });
  };

  scrollToPolisReport = () => {
    const element = document.getElementById('polisReportSection');
    if (element && element.scrollIntoView) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  handleQuestionFilter = (filteredQuestionsOrCombined, newFilterState) => {
    if (Array.isArray(filteredQuestionsOrCombined)) {
      const allowedIds = new Set(filteredQuestionsOrCombined.map((q) => q.id.toLowerCase()));
      if (this.state.viewMode === 'survey' && this.state.surveyViewMode === 'aggregate') {
        const newObj = {};
        for (const qId in this.state.aggregateQuestionResponses) {
          if (allowedIds.has(qId.toLowerCase())) {
            newObj[qId] = this.state.aggregateQuestionResponses[qId];
          }
        }
        let totalR = 0;
        for (const qId in newObj) {
          totalR += newObj[qId].length;
        }
        this.setState({
          sbtFilteredAggregateQuestionResponses: newObj,
          filterState: newFilterState,
          filteredQuestionsCount: filteredQuestionsOrCombined.length,
          filteredResponsesCount: this.state.responses.length
        });
      } else if (this.state.viewMode === 'survey' && this.state.surveyViewMode === 'individuals') {
        this.setState({
          filterState: newFilterState
        });
      } else {
        const newObj = {};
        for (const qId in this.state.aggregatorQuestionResponses) {
          if (allowedIds.has(qId.toLowerCase())) {
            newObj[qId] = this.state.aggregatorQuestionResponses[qId];
          }
        }
        let totalR = 0;
        let filteredQCount = 0;
        for (const qId in newObj) {
          totalR += newObj[qId].length;
          filteredQCount++;
        }
        const realTotalQ = Object.keys(this.state.aggregatorQuestionResponses).length;
        const finalFilteredQCount =
          filteredQCount > realTotalQ ? realTotalQ : filteredQCount;
        this.setState({
          sbtFilteredAggregatorQuestionResponses: newObj,
          filterState: newFilterState,
          filteredQuestionsCount: finalFilteredQCount,
          filteredResponsesCount:
            totalR > this.state.totalResponsesCount ? this.state.totalResponsesCount : totalR
        });
      }
    } else if (
      filteredQuestionsOrCombined &&
      Array.isArray(filteredQuestionsOrCombined.filteredQuestions)
    ) {
      const { filteredQuestions, filteredResponsesByQuestion } = filteredQuestionsOrCombined;
      const allowedIds = new Set(filteredQuestions.map((q) => q.id.toLowerCase()));
      if (this.state.viewMode === 'survey' && this.state.surveyViewMode === 'aggregate') {
        const newObj = {};
        for (const qId in this.state.aggregateQuestionResponses) {
          if (allowedIds.has(qId.toLowerCase())) {
            if (filteredResponsesByQuestion[qId]) {
              newObj[qId] = filteredResponsesByQuestion[qId];
            } else {
              newObj[qId] = this.state.aggregateQuestionResponses[qId];
            }
          }
        }
        let totalR = 0;
        let filteredQCount = 0;
        for (const qId in newObj) {
          totalR += newObj[qId].length;
          filteredQCount++;
        }
        const realTotalQ = Object.keys(this.state.aggregateQuestionResponses).length;
        const finalFilteredQCount =
          filteredQCount > realTotalQ ? realTotalQ : filteredQCount;
        this.setState({
          sbtFilteredAggregateQuestionResponses: newObj,
          filterState: newFilterState,
          filteredQuestionsCount: finalFilteredQCount,
          filteredResponsesCount: this.state.responses.length
        });
      } else if (this.state.viewMode === 'survey' && this.state.surveyViewMode === 'individuals') {
        this.setState({
          filterState: newFilterState
        });
      } else {
        const newObj = {};
        for (const qId in this.state.aggregatorQuestionResponses) {
          if (allowedIds.has(qId.toLowerCase())) {
            if (filteredResponsesByQuestion[qId]) {
              newObj[qId] = filteredResponsesByQuestion[qId];
            } else {
              newObj[qId] = this.state.aggregatorQuestionResponses[qId];
            }
          }
        }
        let totalR = 0;
        let filteredQCount = 0;
        for (const qId in newObj) {
          totalR += newObj[qId].length;
          filteredQCount++;
        }
        const realTotalQ = Object.keys(this.state.aggregatorQuestionResponses).length;
        const finalFilteredQCount =
          filteredQCount > realTotalQ ? realTotalQ : filteredQCount;
        this.setState({
          sbtFilteredAggregatorQuestionResponses: newObj,
          filterState: newFilterState,
          filteredQuestionsCount: finalFilteredQCount,
          filteredResponsesCount:
            totalR > this.state.totalResponsesCount ? this.state.totalResponsesCount : totalR
        });
      }
    }
  };

  setFilterLoading = (loading) => {
    this.setState({ filterLoading: loading });
    if (this.props.setFilterLoading) {
      this.props.setFilterLoading(loading);
    }
  };

  handleFilteredResponses = (filteredResponses) => {
    if (this.state.viewMode === 'survey') {
      if (this.state.surveyViewMode === 'individuals') {
        if (Array.isArray(filteredResponses)) {
          this.setState({ sbtFilteredResponses: filteredResponses }, () => {
            this.setState({ filteredResponsesCount: filteredResponses.length });
          });
        } else {
          console.error(
            'Expected an array in survey mode (individuals), got:',
            filteredResponses
          );
          this.setState({ sbtFilteredResponses: [], filteredResponsesCount: 0 });
        }
      } else {
        if (filteredResponses && typeof filteredResponses === 'object') {
          this.setState({ sbtFilteredAggregateQuestionResponses: filteredResponses }, () => {
            let totalR = 0;
            const qIds = Object.keys(filteredResponses);
            qIds.forEach((q) => {
              totalR += filteredResponses[q].length;
            });
            const realTotalQ = Object.keys(this.state.aggregateQuestionResponses).length;
            const finalQCount = qIds.length > realTotalQ ? realTotalQ : qIds.length;
            this.setState({
              filteredResponsesCount: this.state.responses.length,
              filteredQuestionsCount: finalQCount
            });
          });
        } else {
          console.error('Expected aggregator object in aggregator mode, got:', filteredResponses);
        }
      }
    } else {
      // question mode
      if (filteredResponses && typeof filteredResponses === 'object') {
        this.setState({ sbtFilteredAggregatorQuestionResponses: filteredResponses }, () => {
          let totalQ = Object.keys(filteredResponses).length;
          let totalR = 0;
          for (const qId in filteredResponses) {
            totalR += filteredResponses[qId].length;
          }
          const realTotalQ = Object.keys(this.state.aggregatorQuestionResponses).length;
          const finalQCount = totalQ > realTotalQ ? realTotalQ : totalQ;
          const finalRCount =
            totalR > this.state.totalResponsesCount ? this.state.totalResponsesCount : totalR;
          this.setState({
            filteredQuestionsCount: finalQCount,
            filteredResponsesCount: finalRCount
          });
        });
      } else {
        console.error('Expected aggregator object for question mode, got:', filteredResponses);
      }
    }
  };

  toggleQuestionSummary = (questionId) => {
    this.setState((prevState) => ({
      activeQuestionToggles: {
        ...prevState.activeQuestionToggles,
        [questionId]: !prevState.activeQuestionToggles[questionId]
      }
    }));
  };

  toggleResponse = (index) => {
    this.setState((prevState) => ({
      activeToggles: {
        ...prevState.activeToggles,
        [index]: !prevState.activeToggles[index]
      }
    }));
  };

  toggleSurveyBookmark = (surveyId) => {
    let bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || {
      questionIDs: [],
      surveyIDs: []
    };
    if (!bookmarks.surveyIDs) bookmarks.surveyIDs = [];

    if (bookmarks.surveyIDs.includes(surveyId)) {
      bookmarks.surveyIDs = bookmarks.surveyIDs.filter((id) => id !== surveyId);
    } else {
      bookmarks.surveyIDs.push(surveyId);
    }

    localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
    this.setState({ bookmarkedSurveyIDs: bookmarks.surveyIDs });
  };

  toggleQuestionBookmark = (questionId) => {
    let bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || {
      questionIDs: [],
      surveyIDs: []
    };
    if (!bookmarks.questionIDs) bookmarks.questionIDs = [];

    if (bookmarks.questionIDs.includes(questionId)) {
      bookmarks.questionIDs = bookmarks.questionIDs.filter((id) => id !== questionId);
    } else {
      bookmarks.questionIDs.push(questionId);
    }

    localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
    this.setState({ bookmarkedQuestionIDs: bookmarks.questionIDs });
  };

  renderQuestionSummary = (questionId, responses) => {
    const netId = this.props.network?.id?.toString() || '';
    const lowerQId = questionId.toLowerCase();

    let questionsCacheStr = localStorage.getItem('questionsCache') || '';
    let questionsCache = {};
    try {
      questionsCache = JSON.parse(questionsCacheStr);
    } catch {
      questionsCache = {};
    }
    if (!questionsCache[netId] || !questionsCache[netId].questions) {
      return (
        <Card
          key={questionId}
          id={`questionCard-${questionId}`}
          className={styles.aggregatorSummaryCard}
        >
          <CardHeader
            onClick={() => this.toggleQuestionSummary(questionId)}
            className={styles.questionSummaryHeader}
          >
            <span className={styles.questionTitle}>
              Unknown question: {questionId} ({responses.length} responses)
            </span>
            <FontAwesomeIcon
              icon={faBookmark}
              className={styles.biggerIcon}
              onClick={(e) => {
                e.stopPropagation();
                this.toggleQuestionBookmark(questionId);
              }}
              color={this.state.bookmarkedQuestionIDs.includes(questionId) ? 'gold' : 'white'}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            />
            <FontAwesomeIcon
              icon={
                this.state.activeQuestionToggles[questionId] ? faCaretUp : faCaretDown
              }
              className={styles.biggerIcon}
              style={{ marginLeft: '10px' }}
            />
          </CardHeader>
          <Collapse
            isOpen={this.state.activeQuestionToggles[questionId]}
            id={styles.surveyResultsCollapse}
          >
            <CardBody className={styles.aggregatorDarkCardBody}>
              <p style={{ fontStyle: 'italic', color: '#bbb' }}>
                No metadata found for this question in local cache.
              </p>
              <div className={styles.surveyResultsOverride}>
                <SingleQuestionResponse
                  aggregatorResponseMode={true}
                  question={{ id: questionId, prompt: 'Unknown question' }}
                  allResponses={responses}
                  network={this.props.network}
                />
              </div>
            </CardBody>
          </Collapse>
        </Card>
      );
    }

    const question = questionsCache[netId].questions[lowerQId];
    if (!question) {
      return (
        <Card
          key={questionId}
          id={`questionCard-${questionId}`}
          className={styles.aggregatorSummaryCard}
        >
          <CardHeader
            onClick={() => this.toggleQuestionSummary(questionId)}
            className={styles.questionSummaryHeader}
          >
            <span className={styles.questionTitle}>
              Unknown question: {questionId} ({responses.length} responses)
            </span>
            <FontAwesomeIcon
              icon={faBookmark}
              className={styles.biggerIcon}
              onClick={(e) => {
                e.stopPropagation();
                this.toggleQuestionBookmark(questionId);
              }}
              color={this.state.bookmarkedQuestionIDs.includes(questionId) ? 'gold' : 'white'}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            />
            <FontAwesomeIcon
              icon={
                this.state.activeQuestionToggles[questionId] ? faCaretUp : faCaretDown
              }
              className={styles.biggerIcon}
              style={{ marginLeft: '10px' }}
            />
          </CardHeader>
          <Collapse
            isOpen={this.state.activeQuestionToggles[questionId]}
            id={styles.surveyResultsCollapse}
          >
            <CardBody className={styles.aggregatorDarkCardBody}>
              <p style={{ fontStyle: 'italic', color: '#bbb' }}>
                No metadata found for this question in local cache.
              </p>
              <div className={styles.surveyResultsOverride}>
                <SingleQuestionResponse
                  aggregatorResponseMode={true}
                  question={{ id: questionId, prompt: 'Unknown question' }}
                  allResponses={responses}
                  network={this.props.network}
                />
              </div>
            </CardBody>
          </Collapse>
        </Card>
      );
    }

    const isActive = this.state.activeQuestionToggles[questionId];
    return (
      <Card
        key={questionId}
        id={`questionCard-${questionId}`}
        className={styles.aggregatorSummaryCard}
      >
        <CardHeader
          onClick={() => this.toggleQuestionSummary(questionId)}
          className={styles.questionSummaryHeader}
        >
          <span className={styles.questionTitle}>
            {question.prompt} ({responses.length} responses)
          </span>
          <FontAwesomeIcon
            icon={faBookmark}
            className={styles.biggerIcon}
            onClick={(e) => {
              e.stopPropagation();
              this.toggleQuestionBookmark(questionId);
            }}
            color={this.state.bookmarkedQuestionIDs.includes(questionId) ? 'gold' : 'white'}
            style={{ marginLeft: '10px', cursor: 'pointer' }}
          />
          <FontAwesomeIcon
            icon={isActive ? faCaretUp : faCaretDown}
            className={styles.biggerIcon}
            style={{ marginLeft: '10px' }}
          />
        </CardHeader>
        <Collapse isOpen={isActive} id={styles.surveyResultsCollapse}>
          <CardBody className={styles.aggregatorDarkCardBody}>
            <div className={styles.surveyResultsOverride}>
              <SingleQuestionResponse
                aggregatorResponseMode={true}
                question={question}
                allResponses={responses}
                network={this.props.network}
              />
            </div>
          </CardBody>
        </Collapse>
      </Card>
    );
  };

  renderQuestionIDsTable = (questionMap) => {
    let questionsCacheStr = localStorage.getItem('questionsCache') || '';
    let questionsCache = {};
    try {
      questionsCache = JSON.parse(questionsCacheStr);
    } catch {
      questionsCache = {};
    }

    if (!this.props.network || !this.props.network.id) return null;
    const networkID = this.props.network.id.toString();
    const questionIds = Object.keys(questionMap);

    const questionEntries = questionIds.map((qId) => {
      const responses = questionMap[qId] || [];
      let type = '';
      let prompt = '';
      const lowerQ = qId.toLowerCase();
      if (
        questionsCache[networkID] &&
        questionsCache[networkID].questions &&
        questionsCache[networkID].questions[lowerQ]
      ) {
        type = questionsCache[networkID].questions[lowerQ].type;
        prompt = questionsCache[networkID].questions[lowerQ].prompt;
      }
      return {
        questionId: qId,
        responsesCount: responses.length,
        type,
        prompt
      };
    });

    const { questionIdSortBy, questionIdSortAsc } = this.state;
    questionEntries.sort((a, b) => {
      let cmp = 0;
      if (questionIdSortBy === 'responses') {
        cmp = a.responsesCount - b.responsesCount;
      } else if (questionIdSortBy === 'type') {
        cmp = a.type.localeCompare(b.type);
      } else if (questionIdSortBy === 'prompt') {
        cmp = a.prompt.localeCompare(b.prompt);
      }
      return questionIdSortAsc ? cmp : -cmp;
    });

    return (
      <div className={styles.questionIdTableWrapper}>
        <Table striped bordered hover size="sm" className={styles.questionIdTable}>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Question ID</th>
              <th
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => this.changeQuestionIdSort('prompt')}
              >
                Prompt {questionIdSortBy === 'prompt' ? (questionIdSortAsc ? '▲' : '▼') : '▲▼'}
              </th>
              <th
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => this.changeQuestionIdSort('type')}
              >
                Type {questionIdSortBy === 'type' ? (questionIdSortAsc ? '▲' : '▼') : '▲▼'}
              </th>
              <th
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => this.changeQuestionIdSort('responses')}
              >
                # Responses{' '}
                {questionIdSortBy === 'responses' ? (questionIdSortAsc ? '▲' : '▼') : '▲▼'}
              </th>
              <th style={{ textAlign: 'center' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {questionEntries.map((entry) => {
              const shortened = proposalScripts.getShortenedQuestionID(entry.questionId, false);
              const bookmarked = this.state.bookmarkedQuestionIDs.includes(entry.questionId);
              return (
                <tr key={entry.questionId}>
                  <td style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon
                      icon={faBookmark}
                      style={{ marginRight: '6px', cursor: 'pointer' }}
                      color={bookmarked ? 'gold' : 'white'}
                      onClick={() => this.toggleQuestionBookmark(entry.questionId)}
                    />
                    <a
                      href={`/question/${entry.questionId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.clickableQuestionId}
                    >
                      {shortened}
                    </a>
                  </td>
                  <td className={styles.promptColumn}>{entry.prompt || '(No prompt)'}</td>
                  <td style={{ textAlign: 'center' }}>{entry.type}</td>
                  <td style={{ textAlign: 'center' }}>{entry.responsesCount}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Button
                      size="sm"
                      onClick={() => {
                        this.toggleQuestionSummary(entry.questionId);
                        setTimeout(() => {
                          this.scrollToQuestion(entry.questionId);
                        }, 200);
                      }}
                      className={styles.tableActionButton}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  scrollToQuestion = (questionId) => {
    const element = document.getElementById(`questionCard-${questionId}`);
    if (element && element.scrollIntoView) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  changeQuestionIdSort = (column) => {
    this.setState((prevState) => {
      let newAsc = true;
      if (prevState.questionIdSortBy === column) {
        newAsc = !prevState.questionIdSortAsc;
      }
      return {
        questionIdSortBy: column,
        questionIdSortAsc: newAsc
      };
    });
  };

  toggleQuestionFilter = () => {
    this.setState((prevState) => ({ showQuestionFilter: !prevState.showQuestionFilter }));
  };

  toggleSurveyViewMode = (mode) => {
    this.setState({ surveyViewMode: mode });
  };

  handleManualRefresh = async () => {
    try {
      const latestOnChain = await contractScripts.getLatestBlockNumber(this.props.provider);

      this.setState(
        {
          refreshTargetQuestionBlock: latestOnChain,
          refreshTargetResponseBlock: latestOnChain,
          refreshTargetSurveyBlock: latestOnChain
        },
        async () => {
          if (this.state.viewMode === 'questions') {
            if (this.props.refreshQuestionMetadata) {
              await this.props.refreshQuestionMetadata();
            }
            if (this.props.refreshQuestionResponses) {
              await this.props.refreshQuestionResponses();
            }
          } else if (
            this.state.viewMode === 'survey' &&
            this.props.surveyId &&
            this.props.refreshSurveyResponsesByID
          ) {
            await this.props.refreshSurveyResponsesByID(this.props.surveyId.toLowerCase());
          }
          this.pollLocalStorageForUpdates();
          this.fetchResponses();
        }
      );
    } catch (error) {
      console.error('handleManualRefresh error:', error);
    }
  };

  render() {
    const isActuallyOpen = this.props.isOpen;

    const {
      responses,
      sbtFilteredResponses,
      exportType,
      alertMessage,
      filterLoading,
      showQuestionFilter,
      totalQuestionsCount,
      filteredQuestionsCount,
      totalResponsesCount,
      filteredResponsesCount,
      surveyViewMode,
      aggregatorQuestionResponses,
      sbtFilteredAggregatorQuestionResponses,
      surveyTitle,
      surveyId,
      polisReportSelected,
      viewMode
    } = this.state;

    const netBlock = this.state.networkLatestBlock || 0;
    const { questionLocalBlock, responseLocalBlock, surveyLocalBlock } = this.state;
    const {
      refreshTargetQuestionBlock,
      refreshTargetResponseBlock,
      refreshTargetSurveyBlock
    } = this.state;

    const clampedQuestionLocalBlock = Math.min(questionLocalBlock, netBlock);
    const clampedResponseLocalBlock = Math.min(responseLocalBlock, netBlock);
    const clampedSurveyLocalBlock = Math.min(surveyLocalBlock, netBlock);

    const clampedRefreshTargetQuestionBlock =
      refreshTargetQuestionBlock > 0 ? Math.min(refreshTargetQuestionBlock, netBlock) : 0;
    const clampedRefreshTargetResponseBlock =
      refreshTargetResponseBlock > 0 ? Math.min(refreshTargetResponseBlock, netBlock) : 0;
    const clampedRefreshTargetSurveyBlock =
      refreshTargetSurveyBlock > 0 ? Math.min(refreshTargetSurveyBlock, netBlock) : 0;

    let questionDifference = 0;
    let questionBarText = '';
    let questionProgress = 0;
    let showQuestionSpinner = false;

    if (viewMode === 'questions') {
      if (clampedQuestionLocalBlock === 0 || netBlock === 0) {
        showQuestionSpinner = true;
      } else if (
        clampedRefreshTargetQuestionBlock > 0 &&
        clampedQuestionLocalBlock >= clampedRefreshTargetQuestionBlock
      ) {
        questionProgress = 100;
        questionBarText = `In Sync (Current: ${clampedQuestionLocalBlock} / Latest: ${clampedRefreshTargetQuestionBlock})`;
      } else {
        const denom =
          clampedRefreshTargetQuestionBlock > 0 ? clampedRefreshTargetQuestionBlock : netBlock;
        if (clampedRefreshTargetQuestionBlock === 0) {
          if (clampedQuestionLocalBlock >= netBlock) {
            questionProgress = 100;
            questionBarText = `In Sync (Current: ${clampedQuestionLocalBlock} / Latest: ${netBlock})`;
          } else {
            questionDifference = netBlock - clampedQuestionLocalBlock;
            questionBarText = `Remaining Blocks: ${questionDifference} (Current: ${clampedQuestionLocalBlock} / Latest: ${netBlock})`;
            questionProgress = Math.floor((clampedQuestionLocalBlock / netBlock) * 100);
          }
        } else {
          questionDifference = clampedRefreshTargetQuestionBlock - clampedQuestionLocalBlock;
          questionBarText = (
            <>
              Remaining Blocks: {questionDifference}{' '}
              <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: '6px' }} />
            </>
          );
          questionProgress = denom
            ? Math.floor((clampedQuestionLocalBlock / denom) * 100)
            : 0;
        }
      }
    }

    let showResponseSpinner = false;
    let responseBarText = '';
    let responseProgress = 0;
    let difference = 0;

    if (viewMode === 'survey') {
      if (clampedSurveyLocalBlock === 0 || netBlock === 0) {
        showResponseSpinner = true;
      } else if (
        clampedRefreshTargetSurveyBlock > 0 &&
        clampedSurveyLocalBlock >= clampedRefreshTargetSurveyBlock
      ) {
        responseProgress = 100;
        responseBarText = `In Sync (Current: ${clampedSurveyLocalBlock} / Latest: ${clampedRefreshTargetSurveyBlock})`;
      } else {
        const denom =
          clampedRefreshTargetSurveyBlock > 0 ? clampedRefreshTargetSurveyBlock : netBlock;
        if (clampedRefreshTargetSurveyBlock === 0) {
          if (clampedSurveyLocalBlock >= netBlock) {
            responseProgress = 100;
            responseBarText = `In Sync (Current: ${clampedSurveyLocalBlock} / Latest: ${netBlock})`;
          } else {
            difference = netBlock - clampedSurveyLocalBlock;
            responseBarText = `Remaining Blocks: ${difference} (Current: ${clampedSurveyLocalBlock} / Latest: ${netBlock})`;
            responseProgress = Math.floor((clampedSurveyLocalBlock / netBlock) * 100);
          }
        } else {
          difference = clampedRefreshTargetSurveyBlock - clampedSurveyLocalBlock;
          responseBarText = (
            <>
              Remaining Blocks: {difference}{' '}
              <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: '6px' }} />
            </>
          );
          responseProgress = denom
            ? Math.floor((clampedSurveyLocalBlock / denom) * 100)
            : 0;
        }
      }
    } else {
      if (clampedResponseLocalBlock === 0 || netBlock === 0) {
        showResponseSpinner = true;
      } else if (
        clampedRefreshTargetResponseBlock > 0 &&
        clampedResponseLocalBlock >= clampedRefreshTargetResponseBlock
      ) {
        responseProgress = 100;
        responseBarText = `In Sync (Current: ${clampedResponseLocalBlock} / Latest: ${clampedRefreshTargetResponseBlock})`;
      } else {
        const denom =
          clampedRefreshTargetResponseBlock > 0 ? clampedRefreshTargetResponseBlock : netBlock;
        if (clampedRefreshTargetResponseBlock === 0) {
          if (clampedResponseLocalBlock >= netBlock) {
            responseProgress = 100;
            responseBarText = `In Sync (Current: ${clampedResponseLocalBlock} / Latest: ${netBlock})`;
          } else {
            difference = netBlock - clampedResponseLocalBlock;
            responseBarText = `Remaining Blocks: ${difference} (Current: ${clampedResponseLocalBlock} / Latest: ${netBlock})`;
            responseProgress = Math.floor((clampedResponseLocalBlock / netBlock) * 100);
          }
        } else {
          difference = clampedRefreshTargetResponseBlock - clampedResponseLocalBlock;
          responseBarText = (
            <>
              Remaining Blocks: {difference}{' '}
              <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: '6px' }} />
            </>
          );
          responseProgress = denom
            ? Math.floor((clampedResponseLocalBlock / denom) * 100)
            : 0;
        }
      }
    }

    const questionColor = questionProgress < 100 ? 'info' : 'success';
    const responseColor = responseProgress < 100 ? 'info' : 'success';

    return (
      <Modal
        isOpen={isActuallyOpen}
        toggle={this.closeModal}
        className={styles.resultsModal}
      >
        <ModalHeader toggle={this.closeModal} className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>
            {viewMode === 'survey'
              ? `Survey Results${surveyTitle ? ` - ${surveyTitle}` : ''}`
              : 'Question Results'}
          </h2>
          {viewMode === 'survey' && surveyId && (
            <div
              className={styles.modalSubtitle}
              style={{ color: 'grey', display: 'flex', alignItems: 'center' }}
            >
              ID: {surveyId}
              <FontAwesomeIcon
                icon={faBookmark}
                style={{ marginLeft: '8px', cursor: 'pointer' }}
                color={
                  this.state.bookmarkedSurveyIDs.includes(surveyId) ? 'gold' : 'grey'
                }
                onClick={(e) => {
                  e.stopPropagation();
                  this.toggleSurveyBookmark(surveyId);
                }}
              />
            </div>
          )}

          <div
            className={styles.miniBarContainer}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {viewMode === 'questions' && (
              <div className={styles.miniBarLine} style={{ marginRight: '20px' }}>
                <div className={styles.miniBarLabel}>Questions:</div>
                {showQuestionSpinner ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '6px' }} />
                    <div className={styles.miniBarFraction}>Loading...</div>
                  </>
                ) : (
                  <>
                    <Progress
                      value={questionProgress}
                      color={questionColor}
                      style={{ minWidth: '100px' }}
                      className={styles.miniProgress}
                    />
                    <div className={styles.miniBarFraction}>{questionBarText}</div>
                  </>
                )}
                <FontAwesomeIcon
                  icon={faSyncAlt}
                  className={styles.refreshIcon}
                  onClick={() => this.handleManualRefresh()}
                />
              </div>
            )}

            <div className={styles.miniBarLine}>
              <div className={styles.miniBarLabel}>Responses:</div>
              {showResponseSpinner ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '6px' }} />
                  <div className={styles.miniBarFraction}>Loading...</div>
                </>
              ) : (
                <>
                  <Progress
                    value={responseProgress}
                    color={responseColor}
                    style={{ minWidth: '100px' }}
                    className={styles.miniProgress}
                  />
                  <div className={styles.miniBarFraction}>{responseBarText}</div>
                </>
              )}
              <FontAwesomeIcon
                icon={faSyncAlt}
                className={styles.refreshIcon}
                onClick={() => this.handleManualRefresh()}
              />
            </div>
          </div>
        </ModalHeader>

        <ModalBody className={styles.modalBody}>
          {alertMessage && !filterLoading && (
            <Alert color="info" className={styles.alertMessage}>
              {alertMessage}
            </Alert>
          )}

          {filterLoading && (
            <div className={styles.loadingContainer}>
              <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              <p>Applying filter...</p>
            </div>
          )}

          {viewMode === 'survey' && (
            <div className={styles.surveyViewModeTopButtons}>
              <Button
                color={surveyViewMode === 'individuals' ? 'primary' : 'secondary'}
                onClick={() => this.toggleSurveyViewMode('individuals')}
                style={{ marginRight: '10px' }}
              >
                Individuals View
              </Button>
              <Button
                color={surveyViewMode === 'aggregate' ? 'primary' : 'secondary'}
                onClick={() => this.toggleSurveyViewMode('aggregate')}
              >
                Aggregate View
              </Button>
            </div>
          )}

          {viewMode === 'survey' && surveyViewMode === 'aggregate' && (
            <Card className={styles.questionListCard}>
              <CardHeader
                onClick={() => this.toggleQuestionSummary('__questionList__')}
                className={styles.questionSummaryHeader}
              >
                <span className={styles.questionTitle}>Expand to view question IDs</span>
                <FontAwesomeIcon
                  icon={
                    this.state.activeQuestionToggles['__questionList__']
                      ? faCaretUp
                      : faCaretDown
                  }
                  className={styles.biggerIcon}
                  style={{ marginLeft: '10px' }}
                />
              </CardHeader>
              <Collapse
                isOpen={this.state.activeQuestionToggles['__questionList__']}
                id={styles.surveyResultsCollapse}
              >
                <CardBody className={styles.aggregatorDarkCardBody}>
                  {Object.keys(this.state.sbtFilteredAggregateQuestionResponses).length === 0 &&
                  !filterLoading ? (
                    <p>No questions found.</p>
                  ) : (
                    <div ref={this.questionIdTableRef}>
                      {this.renderQuestionIDsTable(
                        this.state.sbtFilteredAggregateQuestionResponses
                      )}
                    </div>
                  )}
                </CardBody>
              </Collapse>
            </Card>
          )}

          {viewMode === 'questions' && (
            <Card className={styles.questionListCard}>
              <CardHeader
                onClick={() => this.toggleQuestionSummary('__questionList__')}
                className={styles.questionSummaryHeader}
              >
                <span className={styles.questionTitle}>Expand to view question IDs</span>
                <FontAwesomeIcon
                  icon={
                    this.state.activeQuestionToggles['__questionList__']
                      ? faCaretUp
                      : faCaretDown
                  }
                  className={styles.biggerIcon}
                  style={{ marginLeft: '10px' }}
                />
              </CardHeader>
              <Collapse
                isOpen={this.state.activeQuestionToggles['__questionList__']}
                id={styles.surveyResultsCollapse}
              >
                <CardBody className={styles.aggregatorDarkCardBody}>
                  {Object.keys(this.state.sbtFilteredAggregatorQuestionResponses).length === 0 &&
                  !filterLoading ? (
                    <p>No questions found.</p>
                  ) : (
                    <div ref={this.questionIdTableRef}>
                      {this.renderQuestionIDsTable(
                        this.state.sbtFilteredAggregatorQuestionResponses
                      )}
                    </div>
                  )}
                </CardBody>
              </Collapse>
            </Card>
          )}

          <div className={styles.filterSummaryBox}>
            <p style={{ fontStyle: 'italic' }}>
              Total Questions: {totalQuestionsCount}, Filtered Questions: {filteredQuestionsCount}
              <br />
              Total Responses: {totalResponsesCount}, Filtered Responses:{' '}
              {filteredResponsesCount}
            </p>
          </div>

          <div className={styles.exportAndFilterContainer}>
            <div className={styles.filterBox}>
              {viewMode === 'survey' && surveyViewMode === 'individuals' && (
                <Label className={styles.filterBoxLabel}>Filter (Survey Individuals):</Label>
              )}
              {viewMode === 'survey' && surveyViewMode === 'aggregate' && (
                <Label className={styles.filterBoxLabel}>Filter (Survey Aggregate):</Label>
              )}
              {viewMode === 'questions' && (
                <Label className={styles.filterBoxLabel}>
                  Filter Questions &amp; Responses:
                </Label>
              )}

              {viewMode === 'survey' && surveyViewMode === 'aggregate' && (
                <SBTFilter
                  items={this.state.aggregateQuestionResponses}
                  mode="responder"
                  provider={this.props.provider}
                  network={this.props.network}
                  onFilter={this.handleFilteredResponses}
                  setFilterLoading={this.setFilterLoading}
                  autoExpand={false}
                />
              )}
              {viewMode === 'survey' && surveyViewMode === 'individuals' && (
                <SBTFilter
                  items={this.state.responses}
                  mode="responder"
                  provider={this.props.provider}
                  network={this.props.network}
                  onFilter={this.handleFilteredResponses}
                  setFilterLoading={this.setFilterLoading}
                  autoExpand={false}
                />
              )}
              {viewMode === 'questions' && (
                <QuestionFilter
                  resultsMode={true}
                  filterModalOpen={true}
                  toggleFilterModal={this.toggleQuestionFilter}
                  questions={Object.keys(this.state.questionResponses).map((qId) => {
                    const netID = this.props.network?.id?.toString() || '';
                    let qCacheStr = localStorage.getItem('questionsCache') || '{}';
                    let qCache = {};
                    try {
                      qCache = JSON.parse(qCacheStr);
                    } catch {}
                    if (qCache[netID] && qCache[netID].questions && qCache[netID].questions[qId]) {
                      return qCache[netID].questions[qId];
                    }
                    return { id: qId, creator: '', type: '', prompt: '' };
                  })}
                  questionResponses={this.state.questionResponses}
                  provider={this.props.provider}
                  network={this.props.network}
                  onFilter={this.handleQuestionFilter}
                  setFilterLoading={this.setFilterLoading}
                  filterState={this.state.filterState}
                  creatorAndResponderMode={true}
                  showQuestionFilter={showQuestionFilter}
                />
              )}
            </div>

            <div className={styles.exportDataBox}>
              <Label for="exportType" className={styles.exportLabel}>
                Export Data:
              </Label>
              <div id={styles.exportOptions}>
                <UncontrolledDropdown direction="down" className={styles.exportDropdownBox}>
                  <DropdownToggle caret className={styles.exportDropdown}>
                    {exportType}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleExportTypeChange('CSV')}>
                      CSV
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.handleExportTypeChange('TalkToTheCity Report')
                      }
                    >
                      TalkToTheCity Report
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleExportTypeChange('Polis Report')}>
                      Polis Report
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Button onClick={this.downloadCSV} className={styles.downloadButton}>
                  {exportType === 'Polis Report' ? 'Generate' : 'Download'}
                </Button>
              </div>
            </div>
          </div>

          {viewMode === 'survey' && surveyViewMode === 'individuals' && (
            <>
              <div className={styles.responseList}>
                {sbtFilteredResponses.length === 0 && !filterLoading ? (
                  <p>No results yet.</p>
                ) : (
                  sbtFilteredResponses.map((response, index) => {
                    const parsedResponse = this.parseResponse(response.response);
                    const openToggle = !!this.state.activeToggles[index];
                    return (
                      <Card key={index} className={styles.singleResponseCard}>
                        <CardHeader
                          onClick={() => this.toggleResponse(index)}
                          className={styles.responseHeader}
                        >
                          <span className={styles.responderAddress}>
                            {proposalScripts.getShortenedAddress(response.responder)}
                            <a
                              href={`/survey/${this.props.surveyId}/${response.responder}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.externalLink}
                            >
                              <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </a>
                          </span>
                          <FontAwesomeIcon
                            icon={openToggle ? faCaretUp : faCaretDown}
                            className={styles.biggerIcon}
                          />
                        </CardHeader>
                        <Collapse isOpen={openToggle} id={styles.surveyResultsCollapse}>
                          <CardBody className={styles.responseCard}>
                            {parsedResponse &&
                            parsedResponse.responses &&
                            parsedResponse.responses.length > 0 ? (
                              parsedResponse.responses.map((answerItem, aIndex) => {
                                const questionId = (answerItem.questionID || '').toLowerCase();
                                let qCacheStr = localStorage.getItem('questionsCache') || '{}';
                                let qCache = {};
                                try {
                                  qCache = JSON.parse(qCacheStr);
                                } catch {}
                                const netId = this.props.network?.id?.toString() || '';
                                let questionData = {
                                  id: questionId,
                                  creator: '',
                                  type: '',
                                  prompt: ''
                                };
                                if (
                                  qCache[netId] &&
                                  qCache[netId].questions &&
                                  qCache[netId].questions[questionId]
                                ) {
                                  questionData = qCache[netId].questions[questionId];
                                }
                                return (
                                  <div key={aIndex} className={styles.surveyResultsOverride}>
                                    <SingleQuestionResponse
                                      aggregatorResponseMode={false}
                                      question={questionData}
                                      response={answerItem}
                                      mode="fullscreen"
                                      isOwnResponse={
                                        this.props.account?.toLowerCase() ===
                                        response.responder?.toLowerCase()
                                      }
                                      network={this.props.network}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p>No question-level responses found for this user.</p>
                            )}
                          </CardBody>
                        </Collapse>
                      </Card>
                    );
                  })
                )}
              </div>
            </>
          )}

          {viewMode === 'survey' && surveyViewMode === 'aggregate' && (
            <>
              <div className={styles.questionSummaries}>
                {Object.entries(this.state.sbtFilteredAggregateQuestionResponses).map(
                  ([qId, arr]) => {
                    return <div key={qId}>{this.renderQuestionSummary(qId, arr)}</div>;
                  }
                )}
                {Object.keys(this.state.sbtFilteredAggregateQuestionResponses).length === 0 &&
                  !filterLoading && <p>No results yet.</p>}
              </div>
            </>
          )}

          {viewMode === 'questions' && (
            <div className={styles.questionSummaries}>
              {Object.entries(sbtFilteredAggregatorQuestionResponses).map(([qId, arr]) => {
                return <div key={qId}>{this.renderQuestionSummary(qId, arr)}</div>;
              })}
              {Object.keys(sbtFilteredAggregatorQuestionResponses).length === 0 &&
                !filterLoading && <p>No results yet.</p>}
            </div>
          )}

          {polisReportSelected && (
            <div id="polisReportSection">
              <PolisReport
                questionResponses={
                  this.state.viewMode === 'survey' && this.state.surveyViewMode === 'aggregate'
                    ? this.state.sbtFilteredAggregateQuestionResponses
                    : this.state.viewMode === 'survey' && this.state.surveyViewMode === 'individuals'
                    ? {}
                    : this.state.sbtFilteredAggregatorQuestionResponses
                }
                network={this.props.network}
                disclaimersActive={true}
                filterState={this.state.filterState}
              />
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {/* Additional footer actions if needed */}
        </ModalFooter>
      </Modal>
    );
  }
}

export default SurveyResults;
