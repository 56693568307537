import React, { Component } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import Slider from 'react-rangeslider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faCheck,
  faTimes,
  faChevronDown,
  faChevronUp,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';
import styles from './SingleQuestionResponse.module.scss';

/**
 * SingleQuestionResponse is a component responsible for displaying:
 *
 *  (A) A single individual's response to a single question (in "fullscreen" or "mini" mode)
 *
 *  (B) All responses for a single question in aggregator form (when `aggregatorResponseMode === true`)
 *      - Summaries for freeform, binary, rating, multichoice, etc.
 *
 * PROPS:
 *   - question:  object with fields { id, type, prompt, options? } (if aggregatorResponseMode, question is still helpful for the prompt)
 *   - response:  object with structure { answer, additional, importance, ... } (only for single-person mode)
 *   - allResponses: array of { responder, questionId, response, timestamp }
 *       (only for aggregator mode: SurveyResults “aggregate” or “question” mode)
 *   - isOwnResponse: boolean (if single-person mode, indicates user is the responder => can decrypt)
 *   - mode: "fullscreen" | "mini" (applies to single-person response display)
 *   - aggregatorResponseMode: boolean. If true, we show aggregated stats for allResponses.
 *   - showImportance: boolean (whether to show importance slider UI in single-person mode)
 *   - onDecryptQuestion(questionId, fieldToDecrypt): function to decrypt (only relevant if single-person mode)
 *   - responderAddress: string. If provided, we can link out to /question/<questionID>/<responderAddress>
 *   - network: object with `.id` or `.chainId` etc. (for aggregator, we often need question metadata from cache)
 *
 * SCSS is in SingleQuestionResponse.module.scss
 */

class SingleQuestionResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      miniExpanded: false, // used only if mode="mini"
    };
  }

  // Helper function to parse a single response object from chain or local storage
  parseResponse = (resp) => {
    if (!resp) return null;
    try {
      if (typeof resp === 'string') return JSON.parse(resp);
      if (typeof resp === 'object') return resp;
      return null;
    } catch (error) {
      console.error('Error parsing response data in SingleQuestionResponse:', error);
      return null;
    }
  };

  // For aggregator mode (allResponses array). We compute summary stats & display them.
  renderAggregatorByType = () => {
    const { question, allResponses } = this.props;

    // If question is missing, we provide a fallback so we don't just show "Loading question/response..."
    // This way aggregator data is still displayed.
    const aggregatorQuestion = question || {
      prompt: '(No prompt found)',
      type: 'freeform',
      options: [],
    };

    if (!allResponses || !Array.isArray(allResponses)) {
      return <p className={styles.aggregatorText}>No aggregator data available.</p>;
    }

    const questionType = aggregatorQuestion.type || '';

    // Gather only *latest* response per responder
    const responderMap = new Map();
    allResponses.forEach((r) => {
      const existing = responderMap.get(r.responder);
      const existingTs = existing ? parseInt(existing.timestamp) : 0;
      const newTs = parseInt(r.timestamp);
      if (!existing || existingTs < newTs) {
        responderMap.set(r.responder, r);
      }
    });
    const uniqueResps = Array.from(responderMap.values()).map((r) => this.parseResponse(r.response));
    const answered = uniqueResps.filter(Boolean);

    switch (questionType) {
      case 'freeform':
        return this.renderFreeformAggregator(answered);

      case 'binary':
        return this.renderBinaryAggregator(answered);

      case 'rating':
        return this.renderRatingAggregator(answered);

      case 'multichoice':
        return this.renderMultichoiceAggregator(answered, aggregatorQuestion);

      default:
        return (
          <div className={styles.aggregatorText}>
            No aggregator available for question type: {questionType}
          </div>
        );
    }
  };

  /**
   * Freeform aggregator logic:
   * - Tracks how many were encrypted, how many were blank, how many are shown
   * - Summarizes them in one line, e.g. "2 total responses. 0 encrypted responses not shown, 1 blank not shown."
   * - Displays non-empty, unencrypted answers in a simple list
   */
  renderFreeformAggregator = (parsedResponses) => {
    const total = parsedResponses.length;
    if (total === 0) {
      return (
        <div className={`${styles.aggregatorContainer} ${styles.aggregatorText}`}>
          <p className={styles.aggregatorParagraph}>No freeform responses.</p>
        </div>
      );
    }

    let encryptedCount = 0;
    let blankCount = 0;
    let displayedResponses = [];

    parsedResponses.forEach((respObj) => {
      if (!respObj || !respObj.answer) return;
      const val = respObj.answer.value;

      if (respObj.answer.encrypted && val === '*') {
        encryptedCount++;
      } else if (typeof val === 'string' && val.trim() === '') {
        blankCount++;
      } else {
        displayedResponses.push(val);
      }
    });

    // Build a summary line
    // e.g. "2 total responses. 1 encrypted not shown, 1 blank not shown."
    // but omit the piece if it's zero
    const parts = [`${total} total responses.`];
    if (encryptedCount > 0) {
      parts.push(`${encryptedCount} encrypted responses not shown.`);
    } else {
      parts.push(`0 encrypted responses not shown.`);
    }
    if (blankCount > 0) {
      parts.push(`${blankCount} blank not shown.`);
    }

    return (
      <div className={`${styles.aggregatorContainer} ${styles.aggregatorText}`}>
        <p className={styles.aggregatorParagraph}>{parts.join(' ')}</p>
        {displayedResponses.length > 0 && (
          <div className={styles.freeformAggregatorList}>
            {displayedResponses.map((val, index) => (
              <div key={index} className={`${styles.freeformAnswer} ${styles.aggregatorText}`}>
                {typeof val === 'string' ? val : JSON.stringify(val)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  // For aggregator binary
  renderBinaryAggregator = (parsedResponses) => {
    let counts = { Agree: 0, Unsure: 0, Disagree: 0 };
    let total = 0;
    parsedResponses.forEach((resp) => {
      if (resp && resp.answer && resp.answer.value) {
        const val = resp.answer.value;
        if (['Agree', 'Unsure', 'Disagree'].includes(val)) {
          counts[val] = counts[val] + 1;
          total++;
        }
      }
    });
    if (total === 0) {
      return (
        <div className={`${styles.aggregatorContainer} ${styles.aggregatorText}`}>
          <p className={styles.aggregatorParagraph}>No binary responses available.</p>
        </div>
      );
    }
    const percent = (num) => ((num / total) * 100).toFixed(2);

    return (
      <div className={`${styles.aggregatorContainer} ${styles.aggregatorText}`}>
        <p className={styles.aggregatorParagraph}>{total} total binary responses:</p>
        <div className={styles.binaryAggregatorItem}>
          <strong>Agree:</strong> {counts.Agree} ({percent(counts.Agree)}%)
        </div>
        <div className={styles.binaryAggregatorItem}>
          <strong>Unsure:</strong> {counts.Unsure} ({percent(counts.Unsure)}%)
        </div>
        <div className={styles.binaryAggregatorItem}>
          <strong>Disagree:</strong> {counts.Disagree} ({percent(counts.Disagree)}%)
        </div>
      </div>
    );
  };

  // For aggregator rating
  renderRatingAggregator = (parsedResponses) => {
    const values = [];
    parsedResponses.forEach((resp) => {
      if (resp && resp.answer && resp.answer.value !== '' && !isNaN(resp.answer.value)) {
        values.push(Number(resp.answer.value));
      }
    });
    if (values.length === 0) {
      return (
        <div className={`${styles.aggregatorContainer} ${styles.aggregatorText}`}>
          <p className={styles.aggregatorParagraph}>No rating responses available.</p>
        </div>
      );
    }
    const sum = values.reduce((acc, v) => acc + v, 0);
    const avg = sum / values.length;
    const sorted = [...values].sort((a, b) => a - b);
    let median = 0;
    const mid = Math.floor(sorted.length / 2);
    if (sorted.length % 2 === 0) {
      median = (sorted[mid - 1] + sorted[mid]) / 2;
    } else {
      median = sorted[mid];
    }

    return (
      <div className={`${styles.aggregatorContainer} ${styles.aggregatorText}`}>
        <p className={styles.aggregatorParagraph}>
          {values.length} total rating responses.
        </p>
        <p className={styles.aggregatorParagraph}>
          Average: {avg.toFixed(2)}, Median: {median.toFixed(2)}
        </p>
      </div>
    );
  };

  // For aggregator multichoice
  renderMultichoiceAggregator = (parsedResponses, aggregatorQuestion) => {
    const allOptions = aggregatorQuestion.options || [];
    if (!allOptions.length) {
      return (
        <div className={`${styles.aggregatorContainer} ${styles.aggregatorText}`}>
          <p className={styles.aggregatorParagraph}>
            No multichoice options are defined for this question.
          </p>
        </div>
      );
    }
    let totalResponders = 0;
    const countsMap = {};
    allOptions.forEach((opt) => {
      countsMap[opt] = 0;
    });

    parsedResponses.forEach((resp) => {
      if (resp && resp.answer && Array.isArray(resp.answer.value)) {
        totalResponders++;
        resp.answer.value.forEach((choice) => {
          if (countsMap[choice] !== undefined) {
            countsMap[choice] += 1;
          }
        });
      } else if (resp && resp.answer && typeof resp.answer.value === 'string') {
        // Single selection
        if (countsMap[resp.answer.value] !== undefined) {
          countsMap[resp.answer.value] += 1;
        }
        totalResponders++;
      }
    });

    if (totalResponders === 0) {
      return (
        <div className={`${styles.aggregatorContainer} ${styles.aggregatorText}`}>
          <p className={styles.aggregatorParagraph}>No multichoice responses available.</p>
        </div>
      );
    }
    const percent = (num) => ((num / totalResponders) * 100).toFixed(2);

    return (
      <div className={`${styles.aggregatorContainer} ${styles.aggregatorText}`}>
        <p className={styles.aggregatorParagraph}>
          {totalResponders} total responders to this multichoice question.
        </p>
        {allOptions.map((option) => (
          <div key={option} className={styles.multiChoiceOption}>
            <span className={styles.optionLabel}>{option}</span>
            <span className={styles.optionStats}>
              {countsMap[option]} ({percent(countsMap[option])}%)
            </span>
          </div>
        ))}
      </div>
    );
  };

  handleDecryptClick = (field) => {
    const { question, onDecryptQuestion } = this.props;
    if (onDecryptQuestion && question && question.id) {
      onDecryptQuestion(question.id, field);
    }
  };

  toggleMiniExpand = () => {
    this.setState((prev) => ({ miniExpanded: !prev.miniExpanded }));
  };

  // Renders a single-person response to one question
  renderSinglePersonView = () => {
    const { question, response, isOwnResponse, mode, showImportance, responderAddress } =
      this.props;
    const { miniExpanded } = this.state;

    if (!question || !response) {
      // If question or response is missing, show a placeholder
      return (
        <Card
          className={
            mode === 'mini'
              ? styles.miniQuestionContainer
              : styles.fullscreenQuestionContainer
          }
        >
          <CardBody className={styles.questionTitleBody}>
            <h4 className={styles.questionTitle}>Loading question/response...</h4>
          </CardBody>
        </Card>
      );
    }

    const { prompt, type, id } = question;
    const answer = response.answer || {};
    const additional = response.additional || {};
    const importance =
      response.importance !== undefined ? response.importance : null;

    const isAnswerEncrypted = answer.encrypted && answer.value === '*';
    const isAdditionalEncrypted = additional.encrypted && additional.value === '*';

    const containerClass =
      mode === 'fullscreen'
        ? styles.fullscreenQuestionContainer
        : styles.miniQuestionContainer;

    const showFullDetail = mode === 'fullscreen' || miniExpanded;

    const shortQid = proposalScripts.getShortenedQuestionID(id || '', true);
    let externalLink = null;
    if (responderAddress && id) {
      externalLink = `/question/${id}/${responderAddress}`;
    }

    return (
      <Card className={containerClass}>
        <CardBody className={styles.questionTitleBody}>
          {mode === 'mini' && (
            <div className={styles.miniHeader}>
              <div className={styles.miniQuestionSummary}>
                <span className={styles.miniPromptAbbrev}>{prompt}</span>
              </div>
              <Button className={styles.miniExpandButton} onClick={this.toggleMiniExpand}>
                {miniExpanded ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </Button>
            </div>
          )}

          {!showFullDetail && mode === 'mini' && (
            <>
              {isAnswerEncrypted ? (
                isOwnResponse ? (
                  <div>
                    <p className={styles.encryptedResponseText}>This answer is encrypted.</p>
                    <Button
                      onClick={() => this.handleDecryptClick('answer')}
                      className={styles.decryptQuestionButton}
                    >
                      Decrypt Answer
                    </Button>
                  </div>
                ) : (
                  <p className={styles.encryptedResponseText}>This answer is encrypted.</p>
                )
              ) : (
                <>{this.renderAnswerByType(type, answer.value)}</>
              )}
            </>
          )}

          {showFullDetail && (
            <>
              {mode === 'fullscreen' && (
                <h4 className={styles.questionTitle}>{prompt || 'Question'}</h4>
              )}
              {mode === 'mini' && (
                <div className={styles.miniExpandedTopRow}>
                  <div className={styles.miniIdLabel}>ID: {shortQid}</div>
                  {externalLink && (
                    <a
                      href={externalLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.externalLink}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a>
                  )}
                </div>
              )}

              {isAnswerEncrypted ? (
                isOwnResponse ? (
                  <div>
                    <p className={styles.encryptedResponseText}>This answer is encrypted.</p>
                    <Button
                      onClick={() => this.handleDecryptClick('answer')}
                      className={styles.decryptQuestionButton}
                    >
                      Decrypt Answer
                    </Button>
                  </div>
                ) : (
                  <p className={styles.encryptedResponseText}>This answer is encrypted.</p>
                )
              ) : (
                this.renderAnswerByType(type, answer.value)
              )}

              {additional && additional.value ? (
                isAdditionalEncrypted ? (
                  isOwnResponse ? (
                    <div>
                      <p>Additional comments are encrypted.</p>
                      <Button
                        onClick={() => this.handleDecryptClick('additional')}
                        className={styles.decryptQuestionButton}
                      >
                        Decrypt Additional Comments
                      </Button>
                    </div>
                  ) : (
                    <p>Additional comments are encrypted.</p>
                  )
                ) : (
                  <div className={styles.additionalCommentsSection}>
                    <strong className={styles.additionalCommentsLabel}>
                      Additional Comments:
                    </strong>
                    <p className={styles.additionalCommentsContent}>
                      {typeof additional.value === 'string'
                        ? additional.value
                        : JSON.stringify(additional.value)}
                    </p>
                  </div>
                )
              ) : null}

              {showImportance && importance !== null && importance !== undefined && (
                <div className={styles.importanceSlider}>
                  <h6 className={styles.importanceText}>Importance: {importance}</h6>
                  <Slider
                    min={0}
                    max={10}
                    step={1}
                    value={Number(importance)}
                    tooltip={false}
                    disabled={true}
                    style={{ width: '200px' }}
                    className={styles.ratingSlider}
                  />
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    );
  };

  // Renders the user interface
  render() {
    const { aggregatorResponseMode } = this.props;

    if (aggregatorResponseMode) {
      // aggregator summary logic
      return (
        <Card className={styles.fullscreenQuestionContainer}>
          <CardBody className={styles.questionTitleBody}>
            {this.renderAggregatorByType()}
          </CardBody>
        </Card>
      );
    }

    // Otherwise, single-person display
    return this.renderSinglePersonView();
  }

  // For single-person display: renders the answer portion by question type
  renderAnswerByType = (type, value) => {
    if (value === null || value === undefined || value === '') {
      return <div className={styles.freeformAnswer}>No answer provided.</div>;
    }

    switch (type) {
      case 'multichoice':
        if (!Array.isArray(value)) {
          return <div className={styles.freeformAnswer}>No answer provided.</div>;
        }
        return (
          <div className={styles.multichoiceAnswer}>
            {value.map((option, idx) => (
              <div key={idx} className={`${styles.choiceItem} ${styles.selected}`}>
                {option}
              </div>
            ))}
          </div>
        );

      case 'rating':
        return (
          <div className={styles.ratingAnswer}>
            <div
              className={styles.ratingBar}
              style={{ width: `${(Number(value) / 10) * 100}%` }}
            />
            <span>{String(value)}/10</span>
          </div>
        );

      case 'binary':
        return <div className={styles.binaryAnswer}>{String(value)}</div>;

      case 'freeform':
      default:
        return (
          <div className={styles.freeformAnswer}>
            {typeof value === 'string' ? value : JSON.stringify(value)}
          </div>
        );
    }
  };
}

export default SingleQuestionResponse;
