import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

import Explainer from './ExplorerTabPluginExplainer.jsx';

// Example Component JSX
import RiskMatrix from "../MainContent/RiskMatrix.jsx";
// import RiskMatrix from "../DemoPages/ThreeDMatrix.jsx";
import SurveyTool from "../SurveyTool/SurveyTool.jsx";
import PolicyTracker from "../PolicyTracker/PolicyTracker.jsx"
import ModelDirectory from "../ModelDirectory/ModelDirectory.jsx";
import AudioInput from '../SurveyTool/AudioInput.jsx'
// import CreateGroup from '../SBTs/CreateSBTGroup.jsx' // TODO: put in its own category
import SBTsPage from '../SBTs/SBTsPage.jsx'
// Debate Map
import DebateMap from '../DebateMap/AIPolicyAtlas.jsx';
// Database Tool
import DatabaseTool from '../AiDatabase/DatabaseTool.jsx';

// Images and CSS
import nftImage from '../../assets/img/seedsmanTransparent.png';
import riskMatrixImage from '../../assets/img/riskmatrix.jpg';
import modelDirectoryImage from '../../assets/img/modeldirectory.jpg';
import policyTrackerImage from '../../assets/img/policytracker.jpg';
import debateTreeImage from '../../assets/img/debatetree.jpg';
import worldVoteImage from '../../assets/img/worldvote.jpg';
// import constitutionImage from '../../assets/img/constitution.png';
import magnifyingGlassImage from '../../assets/img/magnifyingGlass.png';
//
import mirrors from '../../assets/img/mirrors.png';
import artHeader from '../../assets/img/artHeader.png';
import artHeader2 from '../../assets/img/artHeader2.png';
import butterfly from '../../assets/img/butterfly.png';
// import constitutionImage from '../../assets/img/constitution.png';
import styles from './ExplorerTab.module.scss';
import plusSignImage from '../../assets/img/plusSign.png';
// import instagraphImage from '../../assets/img/instagraph.gif';


// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const netlifyForm = <></>;
{/* <form netlify>
  <input 
    type="text" 
    name="nameInput" 
    placeholder="Optional: Your Name" 
    style={{ fontSize: "1.25em", padding: "20px", background: "transparent", border: "1px solid white", color: "white", fontWeight: "600" }}
  />
  <textarea 
    name="suggestionInput" 
    placeholder="Write about your idea here" 
    style={{ fontSize: "1.25em", padding: "20px", background: "transparent", border: "1px solid white", minHeight: "300px", width: "80%", minWidth: "80%", color: "white", fontWeight: "600"}}
    // onChange={(event) => emailInputChange(event)}
  ></textarea>
  <input 
    type="submit" 
    value="Submit" 
    style={{ fontSize: "1.25em", padding: "20px" }} 
    onclick="this.form.submit()"   />
</form> */}


const ExplorerTab = (props) => {
  const [emailFocus, setEmailFocus] = useState(false);
  const [expandedComponent, setExpandedComponent] = useState(null);
  const [search, setSearch] = useState('');

  // Data for each example component
  const exampleData = [
    {
      name: 'Questions', // Surveys, AI Surveys, AI Questions ...
      subtext: 'Opinions and Priorities',
      explainText: 'Survey and question platform allowing detailed responses, which can be encrypted for privacy and retroactive evaluation by ZK systems (opt-in). An upgraded version of pol.is incorporating advanced question formats, preference weighing, and group filtering. Audio input processed by OpenAI – not stored by this app. Responses stored permanently on Arweave',
      // image: nftImage,
      image: worldVoteImage,
      headerImage: artHeader,
      content: SurveyTool,
      disabled: false,
      status: 'live',
    },
    {
      name: 'Groups', 
      subtext: 'Create and Use',
      explainText: 'Soulbound tokens (non-transferrable NFTs) enable digital groups to organize and coordinate by representing membership, roles, and permissions on-chain. SBTs can create private spaces for collective action, decision-making, and training custom AI models together, unlocking new possibilities for decentralized governance.',
      // image: nftImage,
      image: modelDirectoryImage,
      // headerImage: artHeader2,
      content: SBTsPage,
      disabled: false,
      status: 'live',
    },
    {
      // name: 'Database',
      name: 'Data',
      subtext: 'AI Opinion Database',
      explainText: 'AI Database Tool ingests URLs or PDFs, generates debate-worthy questions using AI, and allows users to select and add these questions to a debate tree and/or question bank.',
      // image: nftImage,
      image: magnifyingGlassImage,
      headerImage: null,
      content: DatabaseTool,
      disabled: false,
      status: 'live',
    },
    {
      name: 'Debate Tree',
      subtext: 'For efficient discourse',
      explainText: 'Demo of an interactive version of Deepmind\'s "AI Policy Atlas". Intended to help users structure and navigate complex arguments, and to help AI researchers and policymakers understand and communicate about AI policy issues. Work in progress.',
      // image: nftImage,
      image: debateTreeImage,
      headerImage: null,
      content: DebateMap,
      disabled: false,
      status: 'future',
    },
    {
      // name: 'Risks / Opps',
      name: 'Risks',
      subtext: 'Track opps and risks',
      explainText: 'A tool for collective "heatmap" tracking of opportunities and risks related to disruptive technologies. Input from other areas of the site could be used to populate and improve this display. Axes could be different, and a 3D version (with subcategories) can be previewed by clicking a row and column',
      image: riskMatrixImage,
      headerImage: null,
      content: RiskMatrix,
      disabled: false,
      status: 'future',
    },
    // {
    //   name: 'Policy Tracker',
    //   subtext: 'By Country',
    //   explainText: '',
    //   // image: nftImage,
    //   image: policyTrackerImage,
    //   headerImage: null,
    //   content: PolicyTracker,
    //   disabled: true,
    //   status: 'future',
    // },
    // {
    //   name: 'AI Model Directory',
    //   subtext: '+ Access Protocols',
    //   explainText: '',
    //   // image: nftImage,
    //   image: modelDirectoryImage,
    //   headerImage: null,
    //   content: ModelDirectory,
    //   disabled: true,
    //   status: 'future',
    // },
    // {
    //   name: 'Supraprompt / Constitution',
    //   subtext: 'Global Guarantees',
    //   // image: nftImage,
    //   image: constitutionImage,
    //   content: <p></p>,
    //   disabled: true,
    //   status: 'future',
    // },
    {
      name: 'Suggest Tool',
      subtext: 'Fill form',
      explainText: 'Suggest a tool or plugin which would aid in coordination between AI researchers and/or the public',
      // image: <FontAwesomeIcon icon={faPlus} />,
      image: plusSignImage,
      headerImage: null,
      // content: <p> </p>,
      content: AudioInput,
      disabled: true,
      status: 'future',
    },
    // {
    //   name: 'Instagraph',
    //   subtext: 'Ask about concepts',
    //   explainText: 'Created by Yohei Nakajima (https://github.com/yoheinakajima/instagraph) and used somewhat incorrectly here. Something like this (+ invoking a model finetuned on AI safety content) could be useful for helping non-technical decision-makers',
    //   // image: <FontAwesomeIcon icon={faPlus} />,
    //   image: plusSignImage,
    //   content: netlifyForm,
    //   disabled: false,
    // },
    // {
    //   name: 'Plugin 8',
    //   subtext: '',
    //   image: nftImage,
    //   content: <p>Custom content for expanded Example 1.</p>,
    //   disabled: true,
    // },
    // {
    //   name: 'Plugin 9',
    //   subtext: '',
    //   image: nftImage,
    //   content: <p>Custom content for expanded Example 1.</p>,
    //   disabled: true,
    // }
    // ... add more custom data objects for each instance
  ];

  const exampleComponents = exampleData.map((data, i) => ({
    Component: data.content,
    props: {
      key: i,
      name: data.name,
      subtext: data.subtext,
      explainText: data.explainText,
      image: data.image,
      disabled: data.disabled,
      status: data.status,
    },
    data: data,
  }));

  const handleClick = (Component, data) => {
    if (!data.disabled) {
      setExpandedComponent(() => ({
        component: Component,
        data: data
      }));
    }
  };

  const resetExpandedComponent = () => {
    setExpandedComponent(null);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // const handleToggle = (openOrNot) => {
  //   console.log("handleToggle()")
  //   props.toggleLoginModal(openOrNot)
  // }

  return (
    <>
      {/* <Container className={styles.searchContainer}>
        <InputGroup>
          <Input
            placeholder="Search for plugins / ask a question / type a command"
            value={search}
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            onChange={handleSearch}
            className={styles.inputBar}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <i className="fas fa-search" id={styles.searchIcon}/>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </Container> */}
      <Container className={`${styles.legendContainer} ${expandedComponent ? styles.hidden : ''}`}>
        <div className={styles.legendItem}>
          <div className={`${styles.legendDot} ${styles.live}`}></div>
          <span className={styles.legendText}>Live</span>
        </div>
        <div className={styles.legendItem}>
          <div className={`${styles.legendDot} ${styles.future}`}></div>
          <span className={styles.legendText}>Future</span>
        </div>
      </Container>
      <Container className={styles.explorerContainer}>
      {expandedComponent ? (
        <div className={styles.breadcrumbContainer}>
            <button className={styles.breadcrumbBtn} onClick={resetExpandedComponent}>
            ← Back 
            {/* To Tools  */}
            {/* → {expandedComponent.data.name} */}
            </button>

            <Explainer 
             explainText={expandedComponent.data.explainText} 
            {...expandedComponent.props} // NOTE: don't think this is working
            />

            {/* <div id={styles.bannerImage}>  */}
            {/* <div id={styles.bannerImageContainer}> 
              <img src={expandedComponent.data.headerImage} id={styles.bannerImage}/> 
            </div> */}

          {/* <button id={styles.siteExplainer} className={styles.siteExplainerButton}
            // onClick={() => this.toggleExplainerModal(0)}
            // onClick={this.props.clickRightArrow}
            > */}
          {/* </button> */}
            {
              React.createElement(
                expandedComponent.component, 
                {
                  ...expandedComponent.data,
                  account: props.account,
                  provider: props.provider,
                  loginComplete: props.loginComplete,
                  toggleLoginModal: props.toggleLoginModal,
                  network: props.network,
                }
              )
            }
        </div>
      ) : (
        <Row className={styles.explorerRow}>
          {exampleComponents.map(({ Component, props, data }, index) => (
            <Col
              key={index}
              xs="12"
              sm="6"
              md="4"
              className={`${styles.explorerCol} ${props.disabled ? styles.disabled : ''} ${styles[props.status]}`}
              onClick={() => handleClick(Component, data)}
            >
              <div className={styles.square}>
                <div
                  className={styles.backgroundImage}
                  style={{
                    backgroundImage: `url(${props.image})`,
                    // border: props.status === 'live' ? '10px solid #4caf50' : props.status === 'future' ? '10px solid #2196f3' : 'none',
                  }}
                ></div>
                <div className={styles.squareText}>{props.name}</div>
                <div className={styles.squareSubtext}>{props.subtext}</div>
              </div>
            </Col>
          ))}
        </Row>
        )}
      </Container>
    </>
  );
};

export default ExplorerTab;
