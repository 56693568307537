import React from 'react';
import { Button, FormGroup, Label, CustomInput } from 'reactstrap';
import { ethers } from 'ethers';
import styles from './SBTSelector.module.scss';
import { faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

// Smart Contracts
import contractScripts from '../Buttons/contractScripts.js';
import { featured_SBTs_LIST } from '../../variables/CONTRACT_ADDRESSES.js';

class SBTSelector extends React.Component {
  state = {
    customSBTAddress: '',
    sbtOptions: [],
    showManualInput: false,
    selectedOption: null,
    loadingOptions: false, // Added this state
  };

  componentDidMount() {
    this.loadSBTOptions();
  }

  loadSBTOptions = async () => {
    this.setState({ loadingOptions: true }); // Start loading
    const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    const networkID = this.props.network?.id;
    if (!networkID) {
      console.error('Network ID is undefined in SBTSelector. Cannot proceed.');
      this.setState({ loadingOptions: false }); // Stop loading
      return;
    }
    let sbtList = sbtCache[networkID] ? sbtCache[networkID].sbtList : {};

    // Include featured SBTs
    for (const sbtAddress of featured_SBTs_LIST) {
      const lowerCaseSbtAddress = sbtAddress.toLowerCase();
      if (!sbtList[lowerCaseSbtAddress]) {
        // Fetch metadata
        try {
          const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, sbtAddress);
          sbtList[lowerCaseSbtAddress] = {
            sbtAddress: sbtAddress,
            sbtInfo,
          };
          // Update cache
          if (!sbtCache[networkID]) {
            sbtCache[networkID] = { sbtList: {} };
          }
          sbtCache[networkID].sbtList[lowerCaseSbtAddress] = sbtList[lowerCaseSbtAddress];
          localStorage.setItem('sbtCache', JSON.stringify(sbtCache));
        } catch (error) {
          console.error('Error fetching SBT metadata:', error);
          // Handle error appropriately
        }
      }
    }

    // Build sbtOptions without duplicates
    const sbtOptionsMap = new Map();

    Object.values(sbtList).forEach(sbt => {
      if (sbt) {
        const sbtInfo = sbt.sbtInfo;
        const sbtAddressOrNull = sbt.sbtAddress;
        if (sbtAddressOrNull) {
          const address = sbtAddressOrNull.toLowerCase();
          if (!sbtOptionsMap.has(address)) {
            sbtOptionsMap.set(address, {
              address: address,
              name: sbtInfo && sbtInfo.name ? sbtInfo.name : 'Unnamed SBT',
              image: sbtInfo && sbtInfo.image ? sbtInfo.image : null,
            });
          }
        } else {
          console.warn('SBT without address:', sbt);
        }
      } else {
        console.warn('Undefined sbt in sbtList:', sbtList);
      }
    });

    const sbtOptions = Array.from(sbtOptionsMap.values());

    this.setState({ sbtOptions, loadingOptions: false }); // Stop loading
  };

  handleSBTSelection = async (selectedOption) => {
    if (!selectedOption) return;
    const selectedAddress = selectedOption.value.toLowerCase();
    if (selectedAddress && ethers.utils.isAddress(selectedAddress)) {
      // Check if already selected in parent component
      const alreadySelected = this.props.selectedSBTs.find(
        sbt => sbt.address.toLowerCase() === selectedAddress
      );
      if (!alreadySelected) {
        let selectedSBT = this.state.sbtOptions.find(
          sbt => sbt.address.toLowerCase() === selectedAddress
        );
        if (!selectedSBT) {
          // SBT not in options, need to fetch metadata
          try {
            const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, selectedAddress);
            selectedSBT = {
              address: selectedAddress,
              name: sbtInfo.name || selectedAddress,
              image: sbtInfo.image || null,
            };
          } catch (error) {
            console.error('Error fetching SBT metadata:', error);
            selectedSBT = { address: selectedAddress, name: 'Unnamed SBT', image: null };
          }
        }
        this.setState({ selectedOption: null }); // Reset selector
        this.props.onAddSBT(selectedSBT); // Pass the sbt object
      }
    }
  };
  

  handleCustomSBTAddressInput = (e) => {
    this.setState({ customSBTAddress: e.target.value });
  };

  handleAddCustomSBT = async () => {
    const { customSBTAddress } = this.state;
    const customAddressLower = customSBTAddress.toLowerCase();
    if (customSBTAddress && ethers.utils.isAddress(customSBTAddress)) {
      // Check if already selected in parent component
      const alreadySelected = this.props.selectedSBTs.find(
        sbt => sbt.address.toLowerCase() === customAddressLower
      );
      if (!alreadySelected) {
        // Fetch metadata
        let sbtName = customSBTAddress;
        let sbtImage = null;
        try {
          const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, customSBTAddress);
          sbtName = sbtInfo.name || customSBTAddress;
          sbtImage = sbtInfo.image || null;
          // Update cache
          const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
          const networkID = this.props.network?.id;
          if (!sbtCache[networkID]) {
            sbtCache[networkID] = { sbtList: {} };
          }
          sbtCache[networkID].sbtList[customAddressLower] = {
            sbtAddress: customSBTAddress,
            sbtInfo,
          };
          localStorage.setItem('sbtCache', JSON.stringify(sbtCache));
        } catch (error) {
          console.error('Error fetching SBT metadata:', error);
        }
        const customSBT = { address: customAddressLower, name: sbtName, image: sbtImage };
        this.setState({ customSBTAddress: '' });
        this.props.onAddSBT(customSBT);
      }
    }
  };
  
  toggleManualInput = () => {
    this.setState(prevState => ({ showManualInput: !prevState.showManualInput }));
  };

  formatOptionLabel = ({ label, image, value }) => (
    <div className={styles.optionLabel}>
      {image && <img src={image} alt="" className={styles.optionImage} />}
      <span>{label}</span>
    </div>
  );

  render() {
    const { customSBTAddress, sbtOptions, showManualInput, selectedOption, loadingOptions } = this.state;
    // We expect `selectedSBTs` and `onRemoveSBT` to be passed in from the parent if we want to display existing selections.

    return (
      <div className={styles.sbtSelector}>
        <FormGroup>
          <Label className={styles.sbtLabel}>{this.props.label || 'Select SBTs:'}</Label>
          <Select
            id={`sbtDropdown-${this.props.id}`}
            options={sbtOptions.map((sbt) => ({
              value: sbt.address,
              label: sbt.name,
              image: sbt.image,
            }))}
            onChange={this.handleSBTSelection}
            className={styles.sbtDropdown}
            formatOptionLabel={this.formatOptionLabel}
            styles={{
              option: (provided) => ({ ...provided, color: 'black' }),
              singleValue: (provided) => ({ ...provided, color: 'black' }),
              input: (provided) => ({ ...provided, color: 'black' }),
              control: (provided) => ({ ...provided, color: 'black' }),
              menu: (provided) => ({ ...provided, color: 'black' }),
            }}
            value={selectedOption}
            placeholder="Select SBT..."
            isLoading={loadingOptions}
          />
        </FormGroup>

        <FormGroup>
          <CustomInput
            type="switch"
            id={`manualInputToggle-${this.props.id}`}
            name={`manualInputToggle-${this.props.id}`}
            label="Manual SBT Address Input"
            checked={showManualInput}
            onChange={this.toggleManualInput}
          />
        </FormGroup>

        {showManualInput && (
          <div>
            <input
              id={`customSbtAddressInput-${this.props.id}`}
              type="text"
              placeholder="Enter SBT Ethereum address"
              value={customSBTAddress}
              onChange={this.handleCustomSBTAddressInput}
              className={styles.sbtAddressInput}
              style={{ color: 'black' }}
            />
            <Button
              id={`customSbtAddButton-${this.props.id}`}
              onClick={this.handleAddCustomSBT}
              className={styles.sbtAddButton}
              disabled={!ethers.utils.isAddress(customSBTAddress)}
            >
              Add Address
            </Button>
          </div>
        )}

        {/* Render the currently selected SBTs (if any) with remove icon and external link */}
        {this.props.selectedSBTs && this.props.selectedSBTs.length > 0 && (
          <div className={styles.selectedAddresses}>
            {this.props.selectedSBTs.map((sbt) => (
              <div key={sbt.address} className={styles.addressTag}>
                <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                <FontAwesomeIcon
                  icon={faTimes}
                  className={styles.removeIcon}
                  size="lg"
                  onClick={() => {
                    if (this.props.onRemoveSBT) {
                      this.props.onRemoveSBT(sbt.address);
                    }
                  }}
                />
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  className={styles.linkIcon}
                  size="lg"
                  onClick={() => window.open(`/sbt/${sbt.address}`, '_blank')}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

}

export default SBTSelector;
