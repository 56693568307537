import React from 'react';
import { FormGroup, Label, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from './SBTSelector.module.scss';
import SBTSelector from './SBTSelector.jsx';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSpinner, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

/**
 * Helper to unify an aggregator or a question array with all known questions from questionsCache,
 * so zero-response or standalone questions won't get dropped. The logic is used when mode indicates
 * question-based filtering (“creator”, “creatorAndResponder”, etc.).
 */
function unifyAggregatorWithAllLocalQuestions(baseItems, networkID, mode) {
  if (!networkID) return baseItems;

  let questionsCacheStr = localStorage.getItem('questionsCache') || '';
  let questionsCache = {};
  try {
    questionsCache = JSON.parse(questionsCacheStr);
  } catch (err) {
    questionsCache = {};
  }
  if (!questionsCache[networkID] || !questionsCache[networkID].questions) {
    return baseItems;
  }

  const allKnownQIDs = Object.keys(questionsCache[networkID].questions || {});

  // If baseItems is an array of question objects, unify by adding missing from cache
  if (Array.isArray(baseItems)) {
    const existingIDs = new Set(baseItems.map((q) => (q.id || '').toLowerCase()));
    const newArray = [...baseItems];
    allKnownQIDs.forEach((qIdLower) => {
      if (!existingIDs.has(qIdLower)) {
        const qObj = questionsCache[networkID].questions[qIdLower];
        if (qObj) newArray.push({ ...qObj });
      }
    });
    return newArray;
  }

  // If baseItems is an aggregator object => questionID => array of responses
  if (typeof baseItems === 'object' && baseItems !== null) {
    const newObj = { ...baseItems };
    allKnownQIDs.forEach((qIdLower) => {
      if (!newObj[qIdLower]) {
        newObj[qIdLower] = [];
      }
    });
    return newObj;
  }

  return baseItems;
}

class SBTFilter extends React.Component {
  constructor(props) {
    super(props);
    // We store all local filter states in one object. If parent passes `externalSBTFilterState`, restore them here.
    this.state = {
      selectedSBTGroupsCreator:
        (props.externalSBTFilterState && props.externalSBTFilterState.selectedSBTGroupsCreator) ||
        [],
      excludedSBTGroupsCreator:
        (props.externalSBTFilterState && props.externalSBTFilterState.excludedSBTGroupsCreator) ||
        [],
      selectedSBTGroupsResponder:
        (props.externalSBTFilterState && props.externalSBTFilterState.selectedSBTGroupsResponder) ||
        [],
      excludedSBTGroupsResponder:
        (props.externalSBTFilterState && props.externalSBTFilterState.excludedSBTGroupsResponder) ||
        [],
      selectedSBTGroups:
        (props.externalSBTFilterState && props.externalSBTFilterState.selectedSBTGroups) || [],
      excludedSBTGroups:
        (props.externalSBTFilterState && props.externalSBTFilterState.excludedSBTGroups) || [],
      onlyVerifiedHumans:
        (props.externalSBTFilterState && props.externalSBTFilterState.onlyVerifiedHumans) || false,
      showFilterOptions: props.autoExpand || false,
      loading: false,

      // Snapshot to compare so we don't re-apply identical filters over and over
      lastAppliedFilterSnapshot: null
    };
  }

  componentDidMount() {
    // If there's an existing external state that implies active filters, apply them once on mount
    if (this.hasAnyFilterActive()) {
      this.applyFilter();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // If local state or props changed in a relevant way, re-apply
    const fieldsToCheck = [
      'selectedSBTGroupsCreator',
      'excludedSBTGroupsCreator',
      'selectedSBTGroupsResponder',
      'excludedSBTGroupsResponder',
      'selectedSBTGroups',
      'excludedSBTGroups',
      'onlyVerifiedHumans'
    ];

    let shouldReapply = false;
    for (let f of fieldsToCheck) {
      if (prevState[f] !== this.state[f]) {
        shouldReapply = true;
        break;
      }
    }

    if (prevProps.items !== this.props.items || prevProps.mode !== this.props.mode) {
      shouldReapply = true;
    }

    if (shouldReapply) {
      this.applyFilter();
    }
  }

  hasAnyFilterActive() {
    const {
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans
    } = this.state;

    return (
      selectedSBTGroupsCreator.length > 0 ||
      excludedSBTGroupsCreator.length > 0 ||
      selectedSBTGroupsResponder.length > 0 ||
      excludedSBTGroupsResponder.length > 0 ||
      selectedSBTGroups.length > 0 ||
      excludedSBTGroups.length > 0 ||
      onlyVerifiedHumans
    );
  }

  getLocalFilterState() {
    return {
      selectedSBTGroupsCreator: this.state.selectedSBTGroupsCreator,
      excludedSBTGroupsCreator: this.state.excludedSBTGroupsCreator,
      selectedSBTGroupsResponder: this.state.selectedSBTGroupsResponder,
      excludedSBTGroupsResponder: this.state.excludedSBTGroupsResponder,
      selectedSBTGroups: this.state.selectedSBTGroups,
      excludedSBTGroups: this.state.excludedSBTGroups,
      onlyVerifiedHumans: this.state.onlyVerifiedHumans
    };
  }

  applyFilter = async () => {
    const { mode, provider, network } = this.props;
    let { items } = this.props; // We'll rename or use a local variable to avoid reassigning `const`
    if (!items) {
      if (this.props.onFilter) {
        this.props.onFilter([], this.getLocalFilterState());
      }
      return;
    }

    const networkID = network?.id?.toString() || '';

    // Build a snapshot
    const snapObj = {
      selectedSBTGroupsCreator: this.state.selectedSBTGroupsCreator,
      excludedSBTGroupsCreator: this.state.excludedSBTGroupsCreator,
      selectedSBTGroupsResponder: this.state.selectedSBTGroupsResponder,
      excludedSBTGroupsResponder: this.state.excludedSBTGroupsResponder,
      selectedSBTGroups: this.state.selectedSBTGroups,
      excludedSBTGroups: this.state.excludedSBTGroups,
      onlyVerifiedHumans: this.state.onlyVerifiedHumans,
      mode,
      itemCount: Array.isArray(items)
        ? items.length
        : typeof items === 'object'
        ? Object.keys(items).length
        : items
    };
    const newFilterSnapshot = JSON.stringify(snapObj);
    if (this.state.lastAppliedFilterSnapshot === newFilterSnapshot) {
      // Already applied these exact filters
      return;
    }
    this.setState({ lastAppliedFilterSnapshot: newFilterSnapshot, loading: true });

    // If no filters are active, pass items straight through
    if (!this.hasAnyFilterActive()) {
      if (this.props.onFilter) {
        this.props.onFilter(items, this.getLocalFilterState());
      }
      this.setState({ loading: false });
      return;
    }

    // ADDED FIX: We'll store the unified data in a **local** variable so we don’t reassign a const
    let localItems = items;

    // If this is a question-based mode, unify aggregator/array with local questions
    if (
      (mode === 'creatorAndResponder' ||
        mode === 'creator' ||
        mode === 'questions' ||
        mode === 'questionResponses') &&
      localItems
    ) {
      localItems = unifyAggregatorWithAllLocalQuestions(localItems, networkID, mode);
    }

    // Now proceed with normal filter logic on localItems
    const allSBTAddresses = new Set([
      ...this.state.selectedSBTGroupsCreator.map((sbt) => sbt.address.toLowerCase()),
      ...this.state.excludedSBTGroupsCreator.map((sbt) => sbt.address.toLowerCase()),
      ...this.state.selectedSBTGroupsResponder.map((sbt) => sbt.address.toLowerCase()),
      ...this.state.excludedSBTGroupsResponder.map((sbt) => sbt.address.toLowerCase()),
      ...this.state.selectedSBTGroups.map((sbt) => sbt.address.toLowerCase()),
      ...this.state.excludedSBTGroups.map((sbt) => sbt.address.toLowerCase())
    ]);

    // Retrieve or fetch minted/burned addresses from local sbtCache
    const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    let sbtListData = {};
    if (sbtCache[networkID] && sbtCache[networkID].sbtList) {
      sbtListData = sbtCache[networkID].sbtList;
    }

    const sbtHoldersMap = {};
    for (const sbtAddress of allSBTAddresses) {
      let mintedAddresses = [];
      let burnedAddresses = [];
      let netMintedAddresses = [];
      if (
        sbtListData[sbtAddress] &&
        sbtListData[sbtAddress].mintedAddresses &&
        sbtListData[sbtAddress].burnedAddresses
      ) {
        mintedAddresses = sbtListData[sbtAddress].mintedAddresses.map((addr) => addr.toLowerCase());
        burnedAddresses = sbtListData[sbtAddress].burnedAddresses.map((addr) => addr.toLowerCase());
        netMintedAddresses = mintedAddresses.filter((addr) => !burnedAddresses.includes(addr));
        sbtHoldersMap[sbtAddress] = new Set(netMintedAddresses);
      } else {
        try {
          mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(provider, sbtAddress);
          burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(provider, sbtAddress);
          mintedAddresses = mintedAddresses.map((addr) => addr.toLowerCase());
          burnedAddresses = burnedAddresses.map((addr) => addr.toLowerCase());
          netMintedAddresses = mintedAddresses.filter((addr) => !burnedAddresses.includes(addr));
          sbtHoldersMap[sbtAddress] = new Set(netMintedAddresses);

          if (!sbtCache[networkID]) {
            sbtCache[networkID] = { sbtList: {} };
          }
          if (!sbtCache[networkID].sbtList[sbtAddress]) {
            sbtCache[networkID].sbtList[sbtAddress] = {};
          }
          sbtCache[networkID].sbtList[sbtAddress].mintedAddresses = mintedAddresses;
          sbtCache[networkID].sbtList[sbtAddress].burnedAddresses = burnedAddresses;
          localStorage.setItem('sbtCache', JSON.stringify(sbtCache));
        } catch (error) {
          console.error('Error fetching SBT holders:', error);
          sbtHoldersMap[sbtAddress] = new Set();
        }
      }
    }

    const doesAddressPassFilters = (addr, selectedSBTs, excludedSBTs) => {
      if (!addr) return true;
      const lowerAddr = addr.toLowerCase();

      // Exclude check
      for (const sbt of excludedSBTs) {
        const sbtAddr = sbt.address.toLowerCase();
        const holdersSet = sbtHoldersMap[sbtAddr];
        if (holdersSet && holdersSet.has(lowerAddr)) {
          return false;
        }
      }
      // Include check
      if (selectedSBTs.length > 0) {
        let holdsIncluded = false;
        for (const sbt of selectedSBTs) {
          const sbtAddr = sbt.address.toLowerCase();
          const holdersSet = sbtHoldersMap[sbtAddr];
          if (holdersSet && holdersSet.has(lowerAddr)) {
            holdsIncluded = true;
            break;
          }
        }
        if (!holdsIncluded) {
          return false;
        }
      }
      return true;
    };

    if (mode === 'creatorAndResponder') {
      // Possibly items is an array of questions or aggregator object. We handle both.
      if (!Array.isArray(localItems)) {
        // If it’s { filteredQuestions, filteredResponsesByQuestion }
        if (localItems.filteredQuestions && Array.isArray(localItems.filteredQuestions)) {
          const { filteredQuestions, filteredResponsesByQuestion } = localItems;

          // Filter question creators
          const finalFilteredQuestions = filteredQuestions.filter((q) =>
            doesAddressPassFilters(
              q.creator,
              this.state.selectedSBTGroupsCreator,
              this.state.excludedSBTGroupsCreator
            )
          );

          // Rebuild the responses for the remaining question IDs
          const finalFilteredResponsesByQ = {};
          finalFilteredQuestions.forEach((qObj) => {
            const qIDlower = (qObj.id || '').toLowerCase();
            finalFilteredResponsesByQ[qIDlower] = filteredResponsesByQuestion[qIDlower] || [];
          });

          // Now filter each response by the responder's SBT
          for (let qID in finalFilteredResponsesByQ) {
            const arr = finalFilteredResponsesByQ[qID];
            const newArr = arr.filter((resp) =>
              doesAddressPassFilters(
                resp.responder,
                this.state.selectedSBTGroupsResponder,
                this.state.excludedSBTGroupsResponder
              )
            );
            finalFilteredResponsesByQ[qID] = newArr;
          }

          if (this.state.onlyVerifiedHumans) {
            // Possibly do more checks
          }

          // Fire any specialized callbacks
          if (this.props.onFilterCreators) {
            this.props.onFilterCreators(finalFilteredQuestions, this.getLocalFilterState());
          }
          if (this.props.onFilterResponders) {
            this.props.onFilterResponders(finalFilteredResponsesByQ, this.getLocalFilterState());
          }
          if (this.props.onFilter) {
            this.props.onFilter(
              {
                filteredQuestions: finalFilteredQuestions,
                filteredResponsesByQuestion: finalFilteredResponsesByQ
              },
              this.getLocalFilterState()
            );
          }

          this.setState({ loading: false });
          return;
        }
        // Otherwise aggregator object questionID => array: we skip extended logic for brevity
      } else {
        // If localItems is just an array of question objects
        const finalQuestions = localItems.filter((qObj) =>
          doesAddressPassFilters(
            qObj.creator,
            this.state.selectedSBTGroupsCreator,
            this.state.excludedSBTGroupsCreator
          )
        );
        if (this.state.onlyVerifiedHumans) {
          // ...
        }
        if (this.props.onFilter) {
          this.props.onFilter(finalQuestions, this.getLocalFilterState());
        }
        this.setState({ loading: false });
        return;
      }
    }

    // Generic item-by-item filter
    const filterItem = (item) => {
      if (!item) return false;

      if (mode === 'addresses') {
        if (typeof item !== 'string') {
          console.error('Expected item to be a string in addresses mode, but got:', item);
          return false;
        }
        const address = item.toLowerCase();

        for (const sbt of this.state.excludedSBTGroups) {
          const sbtAddr = sbt.address.toLowerCase();
          const holders = sbtHoldersMap[sbtAddr];
          if (holders && holders.has(address)) return false;
        }
        if (this.state.selectedSBTGroups.length > 0) {
          let holdsIncludedSBT = false;
          for (const sbt of this.state.selectedSBTGroups) {
            const sbtAddr = sbt.address.toLowerCase();
            const holders = sbtHoldersMap[sbtAddr];
            if (holders && holders.has(address)) {
              holdsIncludedSBT = true;
              break;
            }
          }
          if (!holdsIncludedSBT) return false;
        }
        if (this.state.onlyVerifiedHumans) {
          // ...
        }
        return true;
      } else {
        let addressToCheckCreator = null;
        let addressToCheckResponder = null;

        if (item.creator) {
          addressToCheckCreator = item.creator.toLowerCase();
        }
        if (item.responder) {
          addressToCheckResponder = item.responder.toLowerCase();
        }

        if (mode === 'creator' || mode === 'questions') {
          if (addressToCheckCreator) {
            if (
              !doesAddressPassFilters(
                addressToCheckCreator,
                this.state.selectedSBTGroupsCreator,
                this.state.excludedSBTGroupsCreator
              )
            ) {
              return false;
            }
          }
        }
        if (mode === 'responder' || mode === 'questionResponses') {
          if (addressToCheckResponder) {
            if (
              !doesAddressPassFilters(
                addressToCheckResponder,
                this.state.selectedSBTGroupsResponder,
                this.state.excludedSBTGroupsResponder
              )
            ) {
              return false;
            }
          }
        }
        if (this.state.onlyVerifiedHumans && (addressToCheckCreator || addressToCheckResponder)) {
          // ...
        }
        return true;
      }
    };

    // Handle array vs aggregator object
    let filteredResult;
    if (Array.isArray(localItems)) {
      filteredResult = localItems.filter(filterItem);
    } else if (typeof localItems === 'object') {
      const newObj = {};
      for (const [key, val] of Object.entries(localItems)) {
        if (Array.isArray(val)) {
          const filteredArr = val.filter((subItem) => filterItem(subItem));
          if (filteredArr.length > 0) newObj[key] = filteredArr;
        } else {
          const entries = Object.entries(val);
          const filteredPairs = entries.filter(([resp, respVal]) => filterItem(respVal));
          if (filteredPairs.length > 0) {
            const recon = {};
            for (let [k, v] of filteredPairs) {
              recon[k] = v;
            }
            newObj[key] = recon;
          }
        }
      }
      filteredResult = newObj;
    } else {
      // Unexpected type
      console.warn('SBTFilter: unsupported items type:', typeof localItems);
      filteredResult = localItems;
    }

    this.setState({ loading: false }, () => {
      if (this.props.onFilter) {
        this.props.onFilter(filteredResult, this.getLocalFilterState());
      }
    });
  };

  // Handlers for adding/removing SBT “include” or “exclude”
  handleAddSBTIncludeCreator = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroupsCreator.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        selectedSBTGroupsCreator: [...prev.selectedSBTGroupsCreator, sbtObject]
      }));
    }
  };
  handleRemoveSBTIncludeCreator = (address) => {
    this.setState((prev) => ({
      selectedSBTGroupsCreator: prev.selectedSBTGroupsCreator.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTExcludeCreator = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroupsCreator.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        excludedSBTGroupsCreator: [...prev.excludedSBTGroupsCreator, sbtObject]
      }));
    }
  };
  handleRemoveSBTExcludeCreator = (address) => {
    this.setState((prev) => ({
      excludedSBTGroupsCreator: prev.excludedSBTGroupsCreator.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTIncludeResponder = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroupsResponder.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        selectedSBTGroupsResponder: [...prev.selectedSBTGroupsResponder, sbtObject]
      }));
    }
  };
  handleRemoveSBTIncludeResponder = (address) => {
    this.setState((prev) => ({
      selectedSBTGroupsResponder: prev.selectedSBTGroupsResponder.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTExcludeResponder = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroupsResponder.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        excludedSBTGroupsResponder: [...prev.excludedSBTGroupsResponder, sbtObject]
      }));
    }
  };
  handleRemoveSBTExcludeResponder = (address) => {
    this.setState((prev) => ({
      excludedSBTGroupsResponder: prev.excludedSBTGroupsResponder.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTInclude = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroups.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        selectedSBTGroups: [...prev.selectedSBTGroups, sbtObject]
      }));
    }
  };
  handleRemoveSBTInclude = (address) => {
    this.setState((prev) => ({
      selectedSBTGroups: prev.selectedSBTGroups.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTExclude = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroups.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        excludedSBTGroups: [...prev.excludedSBTGroups, sbtObject]
      }));
    }
  };
  handleRemoveSBTExclude = (address) => {
    this.setState((prev) => ({
      excludedSBTGroups: prev.excludedSBTGroups.filter((sbt) => sbt.address !== address)
    }));
  };

  toggleVerifiedHumans = () => {
    this.setState((prev) => ({
      onlyVerifiedHumans: !prev.onlyVerifiedHumans
    }));
  };

  toggleFilterOptions = () => {
    this.setState((prev) => ({
      showFilterOptions: !prev.showFilterOptions
    }));
  };

  render() {
    const {
      showFilterOptions,
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans,
      loading
    } = this.state;

    const { mode, hideUI } = this.props;

    let buttonText = 'Filter';
    if (
      mode === 'questions' ||
      mode === 'questionResponses' ||
      mode === 'creatorAndResponder'
    ) {
      buttonText = 'Response Filter';
    }
    let loadingText = loading ? 'Applying filter...' : '';

    if (hideUI) {
      return <div style={{ display: 'none' }} />;
    }

    return (
      <div className={styles.sbtFilter}>
        {/* If not autoExpand, show a settings/cog button to toggle filter options */}
        {!this.props.autoExpand && (
          <Button onClick={this.toggleFilterOptions} className={styles.filterButton}>
            <FontAwesomeIcon icon={faCog} /> {buttonText}
          </Button>
        )}

        {/* If autoExpand is true, or the user toggled showFilterOptions, display the filter UI */}
        {(this.props.autoExpand || showFilterOptions) && (
          <div className={styles.filterOptions}>
            {/* Mode: 'responder' => filter by SBT group(s) of Survey Responder */}
            {mode === 'responder' && (
              <>
                <h5>SBT Group(s) of Survey Responder</h5>
                <SBTSelector
                  id="includeResponder"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTIncludeResponder}
                  onRemoveSBT={this.handleRemoveSBTIncludeResponder}
                  selectedSBTs={selectedSBTGroupsResponder}
                  label={'Include Responses from SBT Holders'}
                  // We rely on SBTSelector to display the selected addresses
                />

                <SBTSelector
                  id="excludeResponder"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTExcludeResponder}
                  onRemoveSBT={this.handleRemoveSBTExcludeResponder}
                  selectedSBTs={excludedSBTGroupsResponder}
                  label={'Exclude Responses from SBT Holders'}
                />

                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={onlyVerifiedHumans}
                      onChange={this.toggleVerifiedHumans}
                    />
                    Only see verified human responses
                  </Label>
                </FormGroup>
              </>
            )}

            {/* Mode: 'addresses' => filter addresses by SBT group(s) */}
            {mode === 'addresses' && (
              <>
                <SBTSelector
                  id="includeAddresses"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTInclude}
                  onRemoveSBT={this.handleRemoveSBTInclude}
                  selectedSBTs={selectedSBTGroups}
                  label={'Include Addresses holding SBT'}
                />

                <SBTSelector
                  id="excludeAddresses"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTExclude}
                  onRemoveSBT={this.handleRemoveSBTExclude}
                  selectedSBTs={excludedSBTGroups}
                  label={'Exclude Addresses holding SBT'}
                />

                <FormGroup check id={styles.verifiedHumansLabel}>
                  <Label check className={styles.greyedOutLabel}>
                    <Input
                      type="checkbox"
                      checked={onlyVerifiedHumans}
                      onChange={this.toggleVerifiedHumans}
                      id="humanOnlyCheckbox"
                      disabled={true} // Not yet implemented
                    />
                    <span id="verifiedHumansLabel">
                      Only show verified human addresses<sup>soon</sup>
                    </span>
                  </Label>
                  <UncontrolledTooltip placement="right" target="verifiedHumansLabel">
                    We will use standards like OpenPassport soon.
                  </UncontrolledTooltip>
                </FormGroup>
              </>
            )}

            {/* Mode: 'creator', 'creatorAndResponder', 'questions', or 'questionResponses' => question-based filtering */}
            {(mode === 'creator' ||
              mode === 'creatorAndResponder' ||
              mode === 'questions' ||
              mode === 'questionResponses') && (
              <>
                {/* If creator-based filtering is relevant */}
                {(mode === 'creator' ||
                  mode === 'creatorAndResponder' ||
                  mode === 'questions') && (
                  <>
                    <SBTSelector
                      id="includeCreator"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTIncludeCreator}
                      onRemoveSBT={this.handleRemoveSBTIncludeCreator}
                      selectedSBTs={selectedSBTGroupsCreator}
                      label={'Include Questions created by SBT Holders'}
                    />

                    <SBTSelector
                      id="excludeCreator"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTExcludeCreator}
                      onRemoveSBT={this.handleRemoveSBTExcludeCreator}
                      selectedSBTs={excludedSBTGroupsCreator}
                      label={'Exclude Questions created by SBT Holders'}
                    />
                  </>
                )}

                {/* If responder-based filtering is relevant */}
                {(mode === 'responder' ||
                  mode === 'creatorAndResponder' ||
                  mode === 'questionResponses') && (
                  <>
                    <h5>SBT Group(s) of Question Responder</h5>
                    <SBTSelector
                      id="includeResponder2"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTIncludeResponder}
                      onRemoveSBT={this.handleRemoveSBTIncludeResponder}
                      selectedSBTs={selectedSBTGroupsResponder}
                      label={'Include Responses from SBT Holders'}
                    />

                    <SBTSelector
                      id="excludeResponder2"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTExcludeResponder}
                      onRemoveSBT={this.handleRemoveSBTExcludeResponder}
                      selectedSBTs={excludedSBTGroupsResponder}
                      label={'Exclude Responses from SBT Holders'}
                    />

                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={onlyVerifiedHumans}
                          onChange={this.toggleVerifiedHumans}
                        />
                        Only see verified human responses
                      </Label>
                    </FormGroup>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {loading && (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <p>{loadingText}</p>
          </div>
        )}
      </div>
    );
  }
}

export default SBTFilter;
