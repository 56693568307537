import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faTimes,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import styles from './DatabaseTool.module.scss';

import { callAnthropicAI, fetchContentFromURL } from '../../utilities/aiScripts.js';
import sha256 from 'crypto-js/sha256';
import { seedGenPrompt } from '../../prompts/seedGenPrompt.js';
import AudioInput from '../SurveyTool/AudioInput.jsx';

/**
 * Example helper function to generate AI-based tags for each question prompt.
 * If we have some `preAcceptedTags`, we can do an intersection or fallback.
 */
async function attachAIGeneratedTags(statements, preAcceptedTags = []) {
  const updated = [];
  for (const st of statements) {
    // Minimal tag prompt:
    const prompt = `Please return a JSON array of short, relevant tags for this question:
    """${st.prompt}"""`;
    let candidate = [];
    try {
      const raw = await callAnthropicAI(prompt);
      try {
        candidate = JSON.parse(raw);
      } catch (err) {
        // attempt substring parse
        const matched = raw.match(/\[.*\]/s);
        if (matched) candidate = JSON.parse(matched[0]);
      }
      if (!Array.isArray(candidate)) candidate = [];
      // If we have preAcceptedTags, do intersection
      if (Array.isArray(preAcceptedTags) && preAcceptedTags.length > 0) {
        const lowerSet = new Set(preAcceptedTags.map((t) => t.toLowerCase()));
        const intersection = candidate.filter((x) => lowerSet.has(x.toLowerCase()));
        if (intersection.length > 0) candidate = intersection;
      }
    } catch (err) {
      console.error('Error generating tags for a statement:', err);
    }
    st.tags = candidate;
    updated.push(st);
  }
  return updated;
}

/**
 * Single DatabaseTool component that has two modes:
 * - Full (default) local UI for generating, selecting, uploading
 * - Minified (props.minified=true) that just returns generated questions up to `onQuestionsGenerated`
 */
export default function DatabaseTool({
  provider,
  network,
  account,
  loginComplete,
  toggleLoginModal,
  minified = false,
  preAcceptedTags,
  onQuestionsGenerated,
  hideEncryption = false
}) {
  // States
  const [ingressType, setIngressType] = useState('url'); // default is 'url'
  
  // Single URL field (instead of multiple)
  const [urlInput, setUrlInput] = useState('');

  // For text ingestion with <AudioInput>
  const [pastedText, setPastedText] = useState('');
  const [textEncrypted, setTextEncrypted] = useState(false);

  // For question type checkboxes
  const [questionTypes, setQuestionTypes] = useState({
    binary: true,
    freeform: true,
    rating: true,
    multichoice: true,
  });

  // How many questions to generate (default 10 now)
  const [count, setCount] = useState(10);

  // Local states if not minified
  const [seedStatements, setSeedStatements] = useState([]);
  const [selectedStatements, setSelectedStatements] = useState([]);
  const [answers, setAnswers] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [statementsToUpload, setStatementsToUpload] = useState([]);
  const [prefilledAnswers, setPrefilledAnswers] = useState([]);

  // Clear prior state if questionTypes changes
  useEffect(() => {
    setSeedStatements([]);
    setAnswers({});
    setSelectedStatements([]);
  }, [questionTypes]);

  // Prepare default answers in full mode
  function initializeAnswerStates(statements) {
    const ans = {};
    statements.forEach((s, idx) => {
      switch (s.questionType) {
        case 'binary':
          ans[idx] = 'Unsure';
          break;
        case 'rating':
          ans[idx] = 5;
          break;
        case 'freeform':
          ans[idx] = '';
          break;
        case 'multichoice':
          ans[idx] = [];
          break;
        default:
          ans[idx] = '';
      }
    });
    setAnswers(ans);
  }

  /**
   * Calls the anthropic AI with our seedGenPrompt and merges any new logic
   * to retrieve a "surveyTitle" from the source if we used a URL.
   */
  async function generateSeedStatements(content, questionTypesObj, qty, needSurveyTitle = false) {
    // build the “processed” prompt
    const selectedTypes = Object.keys(questionTypesObj).filter((t) => questionTypesObj[t]);
    const joinedTypes = selectedTypes.join(', ') || 'binary,multichoice,rating,freeform';

    let prompt = seedGenPrompt;
    prompt = prompt.replace('<SourceDocContent>', content);
    prompt = prompt.replace('<NumSeedStatements>', String(qty));
    prompt = prompt.replace('<Types>', joinedTypes);

    // If we want to attempt generating a survey title, add instructions
    if (needSurveyTitle) {
      prompt += `\nAdditionally, guess a short doc title from the source content, and an author name if feasible, in the format "Some Title (Author)". Store it in the JSON output under the key "surveyTitle". If you cannot find an author, just store "surveyTitle" with the best short title you can infer.`;
    }

    console.log('DatabaseTool: calling AI with prompt:', prompt);
    const raw = await callAnthropicAI(prompt);
    let data;
    try {
      data = JSON.parse(raw);
    } catch (err) {
      console.error('Failed to parse AI JSON:', err, raw);
      throw new Error('Invalid AI response format');
    }
    if (!data.questions || !Array.isArray(data.questions)) {
      throw new Error('No “questions” array found in AI result');
    }

    // Filter by selected questionTypes
    let filtered = data.questions.filter((q) => selectedTypes.includes(q.questionType));
    if (filtered.length > qty) {
      filtered = filtered.slice(0, qty);
    }

    // Ensure they have answer/additional
    filtered.forEach((q) => {
      if (!q.answer) q.answer = { value: '', encrypted: false, hash: '' };
      if (!q.additional) q.additional = { value: '', encrypted: false, hash: '' };
    });

    // Attach AI-based tags
    const withTags = await attachAIGeneratedTags(filtered, preAcceptedTags);
    // Also attempt to read the AI-provided "surveyTitle" if present
    const aiTitle = data.surveyTitle || '';
    return { statements: withTags, surveyTitle: aiTitle };
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSeedStatements([]);
    setSelectedStatements([]);
    setAnswers({});

    try {
      let combined = '';
      let needTitle = false;

      if (ingressType === 'text') {
        const text = pastedText.trim();
        if (!text) throw new Error('Please speak or type text to process.');
        if (text.length < 50) {
          throw new Error('Insufficient text length (<50 chars).');
        }
        combined = text;
      } else if (ingressType === 'url') {
        // Single URL approach
        const trimUrl = urlInput.trim();
        if (!trimUrl) {
          throw new Error('Please enter a URL to process.');
        }
        needTitle = true; // we attempt to glean a surveyTitle from doc
        let content = '';
        try {
          content = await fetchContentFromURL(trimUrl);
        } catch (urlErr) {
          console.warn('Error fetching from URL:', urlErr);
          throw new Error(`Failed to fetch URL content: ${urlErr.message}`);
        }
        if (!content || content.length < 50) {
          throw new Error('Not enough content extracted from the URL.');
        }
        combined = content;
      } else {
        throw new Error('Selected ingress type is not supported yet.');
      }

      const { statements, surveyTitle } = await generateSeedStatements(
        combined,
        questionTypes,
        count,
        needTitle
      );

      if (minified) {
        // Convert them to final question objects
        const finalQs = statements.map((st) => ({
          id: generateQuestionId(st.questionType, st.prompt, st.options || []),
          type: st.questionType,
          prompt: st.prompt,
          options: st.questionType === 'multichoice' ? st.options : undefined,
          tags: st.tags || [],
        }));
        // docURLs if user used the single URL
        const docURLs = (ingressType === 'url') ? [urlInput.trim()] : [];

        // Fire callback with possible surveyTitle
        if (typeof onQuestionsGenerated === 'function') {
          onQuestionsGenerated(finalQs, docURLs, surveyTitle);
        }
      } else {
        // In full mode, we store them locally for the user to see
        setSeedStatements(statements);
        initializeAnswerStates(statements);
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }

  function generateQuestionId(type, prompt, options = []) {
    let dataToHash = `${type}:${prompt.toLowerCase()}`;
    if (type === 'multichoice' && options.length > 0) {
      dataToHash += `:${options.join(',').toLowerCase()}`;
    }
    const h = sha256(dataToHash).toString();
    return '0x' + h;
  }

  // If not minified, we allow user to select statements to “upload”
  function handleSelectAll() {
    const allIdx = seedStatements.map((_, i) => i);
    setSelectedStatements(allIdx);
  }
  function handleStatementSelection(idx) {
    setSelectedStatements((prev) => {
      if (prev.includes(idx)) {
        return prev.filter((n) => n !== idx);
      }
      return [...prev, idx];
    });
  }
  async function filterSimilarQuestions(questions) {
    return questions; // stub
  }
  async function handleUploadSelected() {
    const chosen = seedStatements.filter((_, idx) => selectedStatements.includes(idx));
    const filtered = await filterSimilarQuestions(chosen);
    const questionObjs = filtered.map((st) => ({
      id: generateQuestionId(st.questionType, st.prompt, st.options || []),
      type: st.questionType,
      prompt: st.prompt,
      options: st.questionType === 'multichoice' ? st.options : undefined,
      associatedSurveyId: '',
      tags: st.tags || [],
    }));
    const prefAnswers = filtered.map((st, idx) => answers[idx]);
    setStatementsToUpload(questionObjs);
    setPrefilledAnswers(prefAnswers);
  }

  function handleAnswerChange(idx, val) {
    setAnswers((prev) => ({ ...prev, [idx]: val }));
  }
  function handleMultichoiceChange(idx, option) {
    setAnswers((prev) => {
      const curr = prev[idx] || [];
      if (curr.includes(option)) {
        return { ...prev, [idx]: curr.filter((o) => o !== option) };
      } else {
        return { ...prev, [idx]: [...curr, option] };
      }
    });
  }

  // Render question display in full mode
  function renderQuestionDisplay(statement, index) {
    return (
      <div key={index} className={styles.questionDisplay}>
        <div className={styles.questionText}>{statement.prompt}</div>
        <div className={styles.answerDisplay}>
          {statement.questionType === 'binary' && (
            <FormGroup id={styles.binaryChoice}>
              {['Agree', 'Unsure', 'Disagree'].map((option) => (
                <Label
                  check
                  key={option}
                  className={`${styles.radioOptionText} ${
                    styles[option.toLowerCase()]
                  } ${answers[index] === option ? styles.selected : ''}`}
                >
                  <Input
                    type="radio"
                    value={option}
                    checked={answers[index] === option}
                    onChange={() => handleAnswerChange(index, option)}
                  />
                  {option}
                </Label>
              ))}
            </FormGroup>
          )}
          {statement.questionType === 'freeform' && (
            <Input
              type="textarea"
              value={answers[index] || ''}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              placeholder="Your answer here..."
              className={styles.freeformAnswer}
            />
          )}
          {statement.questionType === 'rating' && (
            <div className={styles.ratingAnswer}>
              <input
                type="range"
                min="0"
                max="10"
                value={answers[index] || 5}
                onChange={(e) => handleAnswerChange(index, parseInt(e.target.value))}
                className={styles.ratingSlider}
              />
              <span className={styles.ratingValue}>
                {answers[index] || 5}/10
              </span>
            </div>
          )}
          {statement.questionType === 'multichoice' && (
            <div className={styles.multichoiceAnswer}>
              {statement.options.map((opt, oidx) => (
                <Label check key={oidx} className={styles.multichoiceOption}>
                  <Input
                    type="checkbox"
                    checked={(answers[index] || []).includes(opt)}
                    onChange={() => handleMultichoiceChange(index, opt)}
                  />
                  {opt}
                </Label>
              ))}
            </div>
          )}
        </div>
        <input
          type="checkbox"
          checked={selectedStatements.includes(index)}
          onChange={() => handleStatementSelection(index)}
          className={styles.statementCheckbox}
        />
      </div>
    );
  }

  return (
    <div
      className={
        minified
          ? `${styles.databaseTool} ${styles.minified}`
          : styles.databaseTool
      }
    >
      <div className={styles.header}>
        <h2>AI Database Tool {minified ? '(Minified)' : ''}</h2>
      </div>

      <form onSubmit={handleSubmit}>
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Content to Ingest</h3>
          <div className={styles.ingressOptions}>
            <button
              type="button"
              onClick={() => setIngressType('text')}
              className={`${styles.ingressButton} ${ingressType === 'text' ? styles.active : ''}`}
            >
              Text
            </button>
            <button
              type="button"
              onClick={() => setIngressType('url')}
              className={`${styles.ingressButton} ${ingressType === 'url' ? styles.active : ''}`}
            >
              URL
            </button>
            <button type="button" disabled className={`${styles.ingressButton} ${styles.disabled}`}>
              Multiple (Soon)
            </button>
            <button type="button" disabled className={`${styles.ingressButton} ${styles.disabled}`}>
              Audio (Soon)
            </button>
          </div>

          {ingressType === 'text' && (
            <div className={styles.textInputGroup}>
              <AudioInput
                placeholder="Speak or type text here"
                updateFunction={(val) => setPastedText(val)}
                toggleEncryption={(bool) => setTextEncrypted(bool)}
                value={pastedText}
                encrypted={textEncrypted}
                hideEncryption={hideEncryption}
              />
            </div>
          )}
          {ingressType === 'url' && (
            <div style={{ marginTop: '15px' }}>
              {/* Single URL field */}
              <Input
                type="url"
                value={urlInput}
                onChange={(e) => setUrlInput(e.target.value)}
                placeholder="Enter a URL (http:// or https://)"
                required
              />
            </div>
          )}
          {ingressType !== 'text' && ingressType !== 'url' && (
            <div className={styles.comingSoon}>
              This feature is coming soon.
            </div>
          )}
        </div>

        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Question Types to Generate</h3>
          <div className={styles.questionTypeOptions}>
            {Object.entries(questionTypes).map(([typ, checked]) => (
              <label key={typ} className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() =>
                    setQuestionTypes((prev) => ({ ...prev, [typ]: !prev[typ] }))
                  }
                />
                {typ.charAt(0).toUpperCase() + typ.slice(1)}
              </label>
            ))}
          </div>
        </div>

        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Number of Questions</h3>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Input
              type="number"
              value={count}
              onChange={(e) => setCount(parseInt(e.target.value))}
              min="1"
              max="25"
              required
              style={{ width: '80px' }}
            />
            {/* Quick-select buttons */}
            <Button color="secondary" onClick={() => setCount(10)}>10</Button>
            <Button color="secondary" onClick={() => setCount(15)}>15</Button>
            <Button color="secondary" onClick={() => setCount(20)}>20</Button>
          </div>
        </div>

        <Button type="submit" className={styles.generateButton} disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Generate Questions'}
        </Button>
      </form>

      {error && <div className={styles.error} style={{ marginTop: '10px' }}>{error}</div>}

      {/* If minified, skip local UI */}
      {minified ? null : (
        <>
          {seedStatements.length > 0 && (
            <div className={styles.results}>
              <div className={styles.resultsHeader}>
                <h3>Generated Seed Statements:</h3>
                <button onClick={handleSelectAll} className={styles.selectAllButton}>
                  Select All
                </button>
              </div>
              <div className={styles.statementsContainer}>
                {seedStatements.map((st, idx) => renderQuestionDisplay(st, idx))}
              </div>
              <button onClick={handleUploadSelected} className={styles.addButton}>
                Upload {selectedStatements.length} Selected to Question Bank or Survey
              </button>
            </div>
          )}

          {statementsToUpload.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <p>You can now pass these statements to your Survey creation code.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

// Helper for question ID
function generateQuestionId(type, prompt, options = []) {
  let dataToHash = `${type}:${prompt.toLowerCase()}`;
  if (type === 'multichoice' && options.length > 0) {
    dataToHash += `:${options.join(',').toLowerCase()}`;
  }
  const h = sha256(dataToHash).toString();
  return '0x' + h;
}
