/***************************************************
 * FILE: OnePageDemo.jsx
 * LOCATION: my-app/client/src/components/DemoPages/OnePageDemo.jsx
 ***************************************************/

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

// Import images/logos
import foresightLogo from '../../assets/img/foresight_logo.svg';
import foresightHeader from '../../assets/img/foresight_header.png';

// Styles
import styles from './OnePageDemo.module.scss';

// Sub-components we *do* want in this mini demo:
import SurveyPage from '../SurveyTool/SurveyPage.jsx';
import SBTsPage from '../SBTs/SBTsPage.jsx';
import PolisReport from '../PolisReport/PolisReport.jsx';

/**
 * Helper to transform a typical question-responses object
 * ( shaped like: { questionID: { address1: "...JSON...", address2: "...JSON..." }, ... } )
 * into an aggregator array format
 * ( shaped like: { questionID: [ { responder, questionId, response }, ... ] } )
 * which PolisReport expects.
 */
function convertToAggregator(questionResponsesObj) {
  if (!questionResponsesObj) return {};
  const aggregator = {};
  for (const qId of Object.keys(questionResponsesObj)) {
    aggregator[qId] = [];
    const respondersMap = questionResponsesObj[qId];
    if (respondersMap && typeof respondersMap === 'object') {
      for (const addr of Object.keys(respondersMap)) {
        aggregator[qId].push({
          responder: addr,
          questionId: qId,
          response: respondersMap[qId] // intentionally incorrect?
          // Correction: We need respondersMap[addr], not respondersMap[qId]
        });
      }
    }
  }
  return aggregator;
}

/**
 * Corrected aggregator function: for each qId, map all of its
 * { responder -> stringOrObj } to an array of { responder, questionId, response }.
 */
function convertToAggregatorCorrected(questionResponsesObj) {
  if (!questionResponsesObj) return {};
  const aggregator = {};
  for (const qId of Object.keys(questionResponsesObj)) {
    aggregator[qId] = [];
    const respondersMap = questionResponsesObj[qId];
    if (respondersMap && typeof respondersMap === 'object') {
      for (const addr of Object.keys(respondersMap)) {
        aggregator[qId].push({
          responder: addr,
          questionId: qId,
          response: respondersMap[addr]
        });
      }
    }
  }
  return aggregator;
}

const OnePageDemo = (props) => {
  // Collapsible states
  const [showQuestions, setShowQuestions] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [showResults, setShowResults] = useState(false);

  // NEW: local states for "About" collapsibles
  const [showGroupsAbout, setShowGroupsAbout] = useState(false);
  const [showResultsAbout, setShowResultsAbout] = useState(false);

  // We also want to open SurveyResults (question-mode) programmatically
  // when the user clicks our new "Results" button:
  const [autoOpenResults, setAutoOpenResults] = useState(false);

  // We'll load the questionResponses out of localStorage once (or from props).
  const [questionResponses, setQuestionResponses] = useState({});
  // In some cases you may want disclaimers off. We'll default them "on" here:
  const [disclaimersActive, setDisclaimersActive] = useState(true);
  // For demonstration, we pass a filterState to PolisReport:
  const [filterState, setFilterState] = useState({
    includedSBTs: [],
    excludedSBTs: [],
    onlyVerifiedHumans: false
  });

  // Extract new org-related props (if any):
  const {
    orgName,
    password,
    orgHeader,
    orderHeaderImg,
    orgInfo,
    defaultTags,
    defaultFilterState,
    defaultFeaturedSBTs
  } = props;

  // Base demo URL for resetting the path:
  const baseDemoURL = `/demo${orgName ? `/${orgName}` : ''}${password ? `/${password}` : ''}`;

  /**
   * When user clicks "View Full Results," we expand "Questions" if needed,
   * then push `/questions/results` onto history. SurveyResults
   * detects "/questions" => question mode.
   */
  const handleOpenResults = () => {
    // Ensure "Questions" section is open, so the user sees the relevant info:
    if (!showQuestions) {
      setShowQuestions(true);
    }
    // Push the correct route for question-mode results:
    window.history.pushState({}, '', '/questions/results');
    setAutoOpenResults(true);
  };

  const resetDemoURL = () => {
    window.history.pushState({}, '', baseDemoURL);
  };

  const toggleQuestions = () => {
    setShowQuestions(!showQuestions);
    resetDemoURL();
    // If toggling OFF, also reset autoOpenResults
    if (showQuestions) {
      setAutoOpenResults(false);
    }
  };

  const toggleGroups = () => {
    setShowGroups(!showGroups);
    resetDemoURL();
  };
  const toggleGroupsAbout = () => {
    setShowGroupsAbout(!showGroupsAbout);
  };

  const toggleResults = () => {
    setShowResults(!showResults);
    resetDemoURL();
  };
  const toggleResultsAbout = () => {
    setShowResultsAbout(!showResultsAbout);
  };

  // On mount or when isQuestionCacheReady changes, load questionResponses from localStorage
  useEffect(() => {
    if (props.isQuestionCacheReady && props.network && props.network.id) {
      try {
        const netIdStr = props.network.id.toString();
        const qCacheStr = localStorage.getItem('questionsCache');
        if (qCacheStr) {
          const qCache = JSON.parse(qCacheStr);
          if (
            qCache[netIdStr] &&
            qCache[netIdStr].questionResponses
          ) {
            // This structure is typically { questionId => { userAddr => stringOrObj } }
            setQuestionResponses(qCache[netIdStr].questionResponses);
          }
        }
      } catch (err) {
        console.error("Error parsing questionsCache in OnePageDemo:", err);
      }
    }
  }, [props.isQuestionCacheReady, props.network]);

  // Build aggregator data for PolisReport
  const aggregatorData = convertToAggregatorCorrected(questionResponses);

  return (
    <div className={styles.onePageDemoContainer}>
      {/* -- Simple branding / header area -- */}
      <div className={styles.brandingSection}>
        <img
          src={foresightLogo}
          alt="Foresight Logo"
          className={styles.brandLogo}
        />
        {password && (
          <p id={styles.detectedPassword}>
            (Detected password: <strong>{password}</strong>)
          </p>
        )}

        <div className={styles.eventBlurb}>
          <p id={styles.blurbText}>
            <strong>Vision Weekend</strong> will showcase talks on longevity, rejuvenation, cryonics,
            neurotech, BCIs, cryptography, AI, security, fusion, energy, space, existential hope,
            funding, and innovation. The “Paths to Progress” track highlights 20+ expert
            presentations on breakthroughs shaping a flourishing future. Unconference sessions
            invite deep dives into AI self-regulation, privacy, neuro x AI, and more
          </p>
          <img
            src={foresightHeader}
            alt="Foresight Banner"
            className={styles.headerImage}
          />
          <p id={styles.blurbText}>
            <strong>Context Engine</strong> is software for coordination / discourse in large groups.
            This prototype allows users to create and respond to questions → see what beliefs are
            consensus or controversial by group
          </p>
        </div>
      </div>

      {/* -- Collapsible for "Questions" (SurveyPage) -- */}
      <div className={styles.sectionContainer}>
        <h2 onClick={toggleQuestions} className={styles.sectionHeader}>
          {showQuestions ? (
            <FontAwesomeIcon icon={faCaretUp} className={styles.sectionToggleIcon} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} className={styles.sectionToggleIcon} />
          )}
          Questions ‎  <span className={styles.subTitleDim}> – Answer or Add</span>&nbsp;
        </h2>
        {showQuestions && (
          <div className={styles.miniSectionContent}>
            {1 == 1} ? (
              <SurveyPage
                miniMode={true}
                account={props.account}
                provider={props.provider}
                network={props.network}
                toggleLoginModal={props.toggleLoginModal}
                loginComplete={props.loginComplete}

                // Additional org-based props
                orgInfo={orgInfo}
                defaultTags={defaultTags}
                defaultFilterState={defaultFilterState}

                autoOpenResults={autoOpenResults}
              />
            )

          </div>
        )}
      </div>

      {/* -- Collapsible for "Groups" (SBTs) -- */}
      <div className={styles.sectionContainer}>
        <h2 onClick={toggleGroups} className={styles.sectionHeader}>
          {showGroups ? (
            <FontAwesomeIcon icon={faCaretUp} className={styles.sectionToggleIcon} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} className={styles.sectionToggleIcon} />
          )}
          Groups ‎  <span className={styles.subTitleDim}> – Join or Create</span>&nbsp;
        </h2>
        {showGroups && (
          <div className={styles.miniSectionContent}>

            {/* NEW "About" text area above the featured SBTs */}
            <div className={styles.aboutContainer}>
              <div
                className={styles.aboutHeader}
                onClick={toggleGroupsAbout}
              >
                About&nbsp;
                {showGroupsAbout ? (
                  <FontAwesomeIcon icon={faCaretUp} id={styles.aboutArrowIcon} />
                ) : (
                  <FontAwesomeIcon icon={faCaretDown} id={styles.aboutArrowIcon} />
                )}
              </div>
              {showGroupsAbout && (
                <div className={styles.aboutContent}>
                  <p id={styles.aboutText}>
                    Soulbound tokens (non-transferrable NFTs) enable digital groups to organize and coordinate
                    by representing membership, roles, and permissions on-chain. SBTs can enable
                    private spaces for collective action, decision-making, and training custom
                    AI models together, unlocking new possibilities for decentralized governance.
                  </p>
                  {orgInfo && (
                    <p style={{ fontStyle: 'italic', marginTop: '1rem' }}>
                      (Additional org info: {JSON.stringify(orgInfo)})
                    </p>
                  )}
                </div>
              )}
            </div>

            {props.isSBTCacheReady ? (
              <SBTsPage
                provider={props.provider}
                network={props.network}
                account={props.account}
                loginComplete={props.loginComplete}
                toggleLoginModal={props.toggleLoginModal}
                miniaturized={false}

                // Additional org-based props
                orgInfo={orgInfo}
                defaultFeaturedSBTs={defaultFeaturedSBTs}
              />
            ) : (
              <p style={{ fontStyle: 'italic' }}>
                SBT data loading (cache not ready)...
              </p>
            )}
          </div>
        )}
      </div>

      {/* -- Collapsible for "Results" (PolisReport) -- */}
      <div className={styles.sectionContainer}>
        <h2 onClick={toggleResults} className={styles.sectionHeader}>
          {showResults ? (
            <FontAwesomeIcon icon={faCaretUp} className={styles.sectionToggleIcon} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} className={styles.sectionToggleIcon} />
          )}
          Results ‎  <span className={styles.subTitleDim}> – View or Save</span>&nbsp;
        </h2>
        {showResults && (
          <div className={styles.miniSectionContent}>
            {/* NEW "About" text area at top of "Results" collapsible */}
            <div className={styles.aboutContainer}>
              <div
                className={styles.aboutHeader}
                onClick={toggleResultsAbout}
              >
                About&nbsp;
                {showResultsAbout ? (
                  <FontAwesomeIcon icon={faCaretUp} id={styles.aboutArrowIcon} />
                ) : (
                  <FontAwesomeIcon icon={faCaretDown} id={styles.aboutArrowIcon} />
                )}
              </div>
              {showResultsAbout && (
                <div className={styles.aboutContent}>
                  <p id={styles.aboutText}>
                    This section displays result visualizations. Click "Full Results"
                    above (in Questions) to see more detailed breakdowns of responses, filter
                    by group membership, and export data as an interactive pol.is report
                  </p>
                </div>
              )}

              {/* Example "View Full Results" from this area, too (optional): */}

            </div>

            <div style={{ marginTop: '15px', alignSelf: 'center' }}>
                <button
                  onClick={handleOpenResults}
                  style={{
                    display: 'inline-block',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    padding: '8px 14px',
                    borderRadius: '4px',
                    backgroundColor: '#7063f5',
                    color: '#fff',
                    border: '1px solid #5e54e5',
                    cursor: 'pointer'
                  }}
                >
                  View Full Results
                </button>
              </div>

            {/* Now embed the same PolisReport that SurveyResults uses,
                passing aggregator data, disclaimersActive, filterState, etc. */}
            <div style={{ marginTop: '20px' }}>
              <PolisReport
                onePageDemo={true}
                miniMode={true}
                account={props.account}
                provider={props.provider}
                network={props.network}
                loginComplete={props.loginComplete}

                /* Provide aggregator-style question responses so PolisReport can parse them */
                questionResponses={aggregatorData}
                disclaimersActive={disclaimersActive}
                filterState={filterState}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnePageDemo;
