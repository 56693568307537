import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './SBTsPage.module.scss';
import SBTsList from './SBTsList';
import CreateGroup from './CreateSBTGroup';
import SBTPage from './SBTPage';
import { featured_SBTs_LIST } from '../../variables/CONTRACT_ADDRESSES.js';

class SBTsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSBTsList: false,
      showCreateGroup: false,
      loadingSBTs: true,
    };

    this.toggleSBTsList = this.toggleSBTsList.bind(this);
    this.toggleCreateGroup = this.toggleCreateGroup.bind(this);
  }

  componentDidMount() {
    // Simulate loading of SBTs
    setTimeout(() => {
      this.setState({ loadingSBTs: false });
    }, 1000);
  }

  toggleSBTsList() {
    this.setState(prevState => ({ showSBTsList: !prevState.showSBTsList }));
  }

  toggleCreateGroup() {
    this.setState(prevState => ({ showCreateGroup: !prevState.showCreateGroup }));
  }

  render() {
    const { provider, network, account, loginComplete, toggleLoginModal } = this.props;
    const { showSBTsList, showCreateGroup, loadingSBTs } = this.state;

    console.log('SBTsPage loginComplete:', loginComplete);

    return (
      <div>
        {this.props.miniaturized ? (
          <div>
            <h2 className={styles.featuredTitle}>Featured Groups</h2>
            {loadingSBTs ? (
              <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
            ) : (
              <div className={styles.sbtGrid}>
                {featured_SBTs_LIST.map((sbtAddress, index) => (
                  <SBTPage
                    key={index}
                    SBTAddress={sbtAddress}
                    account={account}
                    provider={provider}
                    network={network}
                    miniaturized={true}
                    /** Pass miniMintable here to show the mint button in mini view */
                    miniMintable={true}
                    loginComplete={loginComplete}
                    toggleLoginModal={toggleLoginModal}
                  />
                ))}
              </div>
            )}

            <h2 className={styles.sectionTitle}>Minting Live</h2>
            <SBTsList
              provider={provider}
              network={network}
              account={account}
              loginComplete={loginComplete}
              miniaturized={true}
              toggleLoginModal={toggleLoginModal}
            />

            <div className={styles.container}>
              <button onClick={() => window.open("/sbts", "_blank")} className={styles.expandButton}>
                <FontAwesomeIcon icon={faExpand} /> View All SBTs
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h2 className={styles.featuredTitle}>Featured Groups</h2>
            {loadingSBTs ? (
              <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
            ) : (
              <div className={styles.sbtGrid}>
                {featured_SBTs_LIST.map((sbtAddress, index) => (
                  <SBTPage
                    key={index}
                    SBTAddress={sbtAddress}
                    account={account}
                    provider={provider}
                    network={network}
                    miniaturized={true}
                    /** Pass miniMintable here to show the mint button in mini view */
                    miniMintable={true}
                    loginComplete={loginComplete}
                    toggleLoginModal={toggleLoginModal}
                  />
                ))}
              </div>
            )}

            <div className={styles.container}>
              <div id={styles.buttonRow}>
                <button onClick={() => window.location.href = "/sbts"} className={styles.backButton}>
                  <FontAwesomeIcon icon={faExpand} /> Full SBTs list
                </button>
                <button id={styles.showResultsButton} onClick={this.toggleCreateGroup}>
                  {showCreateGroup ? "Exit Group Creation" : "Create Group"}
                </button>
              </div>
            </div>

            {showCreateGroup && (
              <CreateGroup
                account={account}
                loginComplete={loginComplete}
                provider={provider}
                toggleLoginModal={toggleLoginModal}
                expanded={showCreateGroup}
                network={network}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default SBTsPage;
